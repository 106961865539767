import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { athleteApi } from './apis/athleteApi';
import { journalApi } from './apis/journalApi';
import { exerciseApi } from './apis/exerciseApi';
import { programApi } from './apis/programApi';
import { workoutApi } from './apis/workoutApi';
import { workoutExerciseApi } from './apis/workoutExerciseApi';
import { setObjectiveApi } from './apis/setObjectiveApi';
import { programAthleteApi } from './apis/programAthleteApi';
import { plicometryApi } from './apis/plicometryApi';
import { dietNeedsApi } from './apis/dietNeedsApi';
import { prApi } from './apis/prApi';
import { fmsApi } from './apis/fmsApi';
import { antropometryApi } from './apis/antropometryApi';
import { coachApi } from './apis/coachApi';
import { dietApi } from './apis/dietApi';
import { exercisesInWorkoutReducer,
  addExerciseToWorkout,
  removeExerciseFromWorkout,
  changeOrderUp,
  changeOrderDown,
  addSet,
  removeSet,
  editSet,
  clean,
  initializewithData,
  switchExercise,
  editExerciseNote
} from './slices/exercisesInWorkoutSlice';
import { workoutsInProgramReducer,
  addWorkoutToProgram,
  removeWorkoutFromProgram, 
  changeProgramOrderUp, 
  changeProgramOrderDown,
  cleanProgram,
  initializeProgramWithData
} from './slices/workoutsInProgramSlice';

import { nutritionFormReducer,changeWeight, changeBodyfat, changeObjective, changeNeatIndex, changeAthleteType, 
  changeDailyProtein, changeDailyLipid, changeSex
} from "./slices/nutritionFormSlice";

import { mealPlanFormReducer, changeNumberMeals, changeNumberSnacks, changePreworkout, changePostworkout, changeRestriction,
  changeMealLanguage, changeAllergies, changeIncludedFoods, changeExcludedFoods, changeComments
} from "./slices/mealPlanFormSlice";

export const store = configureStore({
  reducer: {
    [athleteApi.reducerPath]: athleteApi.reducer,
    [journalApi.reducerPath]: journalApi.reducer,
    [exerciseApi.reducerPath]: exerciseApi.reducer,
    [programApi.reducerPath]: programApi.reducer,
    [workoutApi.reducerPath]: workoutApi.reducer,
    [workoutExerciseApi.reducerPath]: workoutExerciseApi.reducer,
    [setObjectiveApi.reducerPath]: setObjectiveApi.reducer,
    [programAthleteApi.reducerPath]: programAthleteApi.reducer,
    [plicometryApi.reducerPath]: plicometryApi.reducer,
    [dietNeedsApi.reducerPath]: dietNeedsApi.reducer,
    [prApi.reducerPath]: prApi.reducer,
    [antropometryApi.reducerPath]: antropometryApi.reducer,
    [fmsApi.reducerPath]: fmsApi.reducer,
    [coachApi.reducerPath]: coachApi.reducer,
    [dietApi.reducerPath]: dietApi.reducer,
    exercisesInWorkout: exercisesInWorkoutReducer,
    workoutsInProgram: workoutsInProgramReducer,
    nutritionForm: nutritionFormReducer,
    mealPlanForm: mealPlanFormReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(athleteApi.middleware)
      .concat(journalApi.middleware)
      .concat(exerciseApi.middleware)
      .concat(programApi.middleware)
      .concat(workoutApi.middleware)
      .concat(programAthleteApi.middleware)
      .concat(workoutExerciseApi.middleware)
      .concat(setObjectiveApi.middleware)
      .concat(plicometryApi.middleware)
      .concat(dietNeedsApi.middleware)
      .concat(prApi.middleware)
      .concat(antropometryApi.middleware)
      .concat(fmsApi.middleware)
      .concat(coachApi.middleware)
      .concat(dietApi.middleware)
  }
});

setupListeners(store.dispatch);

export { 
  useFetchAthletesQuery, useFetchAthleteQuery, useCreateAthleteMutation, useUpdateAthleteMutation, 
  useFetchAthleteByNativeUserIdQuery, useLazyFetchAthleteByNativeUserIdQuery, 
  useFetchAthleteJournalByNativeUserIdQuery, 
} from './apis/athleteApi';

export { useFetchJournalsQuery, useCreateJournalMutation, useUpdateJournalMutation, useFetchJournalQuery, 
  useFetchJournalByAthleteQuery, useFetchJournalByAthleteAndDateQuery, useFetchJournalUserIdQuery,
  useLazyFetchJournalByAthleteAndDateQuery } from './apis/journalApi';

export { 
  useFetchExercisesQuery, useCreateExerciseMutation, useUpdateExerciseMutation, useDeleteExerciseMutation, useFetchExerciseQuery, useFetchExercisesByNameQuery
} from './apis/exerciseApi';


export { 
  useFetchProgramsQuery, useCreateProgramMutation, useUpdateProgramMutation, useDeleteProgramMutation, useFetchProgramQuery, useFetchAllProgramDetailsByAthleteQuery 
} from './apis/programApi';

export { useFetchWorkoutsQuery, useCreateWorkoutMutation, useUpdateWorkoutMutation, useDeleteWorkoutMutation, useFetchWorkoutQuery, useLazyFetchWorkoutQuery, useFetchAvailableWorkoutsQuery } from './apis/workoutApi';

export { useFetchWorkoutsExercisesQuery, useCreateWorkoutExerciseMutation, useUpdateWorkoutExerciseMutation, useDeleteWorkoutExerciseMutation, useFetchWorkoutExerciseQuery } from './apis/workoutExerciseApi';

export { useFetchSetObjectivesQuery, useCreateSetObjectiveMutation, useUpdateSetObjectiveMutation, useDeleteSetObjectiveMutation, useFetchSetObjectiveQuery } from './apis/setObjectiveApi';

export { useFetchProgramsAthletesQuery, useCreateProgramAthleteMutation, useUpdateProgramAthleteMutation, 
  useDeleteProgramAthleteMutation, useFetchProgramAthleteQuery } from './apis/programAthleteApi';

export { useFetchDietNeedsQuery, useCreateDietNeedMutation, useUpdateDietNeedMutation, useFetchDietNeedQuery, 
  useFetchDietNeedByUserIdQuery, 
    } from './apis/dietNeedsApi'

export { useFetchPlicometriesQuery, useCreatePlicometryMutation, useUpdatePlicometryMutation, useFetchPlicometryQuery, useFetchPlicometryByAtheleteIdQuery  } from './apis/plicometryApi';

export { useFetchRecordsQuery, useCreateRecordMutation, useUpdateRecordMutation, useFetchRecordQuery, 
  useFetchRecordByAthleteIdQuery, 
  } from './apis/prApi';

  export { useFetchAntropometrysQuery, useCreateAntropometryMutation, useUpdateAntropometryMutation, useFetchAntropometryQuery, 
  useFetchAntropometryByAthleteIdQuery, 
  } from './apis/antropometryApi';

  export { useFetchFmssQuery, useCreateFmsMutation, useUpdateFmsMutation, useFetchFmsQuery, 
    useFetchFmsByAthleteIdQuery, 
    } from './apis/fmsApi';

export { useFetchCoachsQuery, useCreateCoachMutation, useUpdateCoachMutation, useFetchCoachQuery, 
  useFetchCoachByAuthIdQuery, useFetchCleanCoachQuery, useLazyFetchCleanCoachQuery } from './apis/coachApi'

export { addExerciseToWorkout, removeExerciseFromWorkout, changeOrderUp, changeOrderDown, addSet, 
  removeSet, editSet, clean, initializewithData, switchExercise, editExerciseNote}//exerciseInWorkoutSlice

export { addWorkoutToProgram, removeWorkoutFromProgram, changeProgramOrderUp, changeProgramOrderDown, 
  cleanProgram, initializeProgramWithData } //workoutInProgramSlice

export { useFetchDietsQuery, useCreateDietMutation, useUpdateDietMutation, useFetchDietQuery, 
  useFetchDietByAthleteIdQuery, 
  } from './apis/dietApi'

export { changeWeight, changeBodyfat, changeObjective, changeNeatIndex ,changeAthleteType,
  changeDailyProtein, changeDailyLipid, changeSex
} // nutritionFormSlice

export { changeNumberMeals, changeNumberSnacks, changePreworkout, changePostworkout, changeRestriction,
  changeMealLanguage, changeAllergies, changeIncludedFoods, changeExcludedFoods, changeComments
} // mealPlanFormSlice;