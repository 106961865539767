import React from 'react';
import { useParams } from 'react-router-dom';
import TableCard from '../../../components/tables/TableCard';
import { useFetchJournalByAthleteQuery } from '../../../store';
import moment from 'moment/moment';
import SleepQualityChart from '../../../components/charts/SleepQualityChart';
import PsychoConditionChart from '../../../components/charts/PsychoConditionChart';
import StepsChart from '../../../components/charts/StepsChart';
import WaterChart from '../../../components/charts/WaterChart';
import DietDiffcultyChart from '../../../components/charts/DietDifficultyChart';
import WeightChart from '../../../components/charts/WeightChart';
import FatigueChart from '../../../components/charts/FatigueChart';
import DesireTrainingChart from '../../../components/charts/DesireTrainingChart';

function JournalManager() {
  let { id } = useParams();
  const { data, error, isLoading} = useFetchJournalByAthleteQuery(id);
  

  if (isLoading) {
    return <div> Loading...</div>;
  }

  if (error) {
    return <div> Error: {error.message}</div>;
  }
  let chartData = [...data];
  chartData.sort((a, b) => {
    // Convertimos las fechas a objetos Date para comparar
    let dateA = new Date(a.date);
    let dateB = new Date(b.date);
    
    // Orden ascendente (dateA - dateB)
    return dateA - dateB;
  });
  const config = [
    {
      key: 'Date',
      label: 'Date',
      render: (journal) => moment(journal.date).format('DD/MM/YYYY'),
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Sleep Quality',
      label: 'Sleep Quality',
      render: (journal) => journal.sleep_quality,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Psychological Condition',
      label: 'Psychological Condition',
      render: (journal) => journal.psychological_condition,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Desire Levels of Training',
      label: 'Desire Levels of Training',
      render: (journal) => journal.desire_training,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Fatigue',
      label: 'Fatigue',
      render: (journal) => journal.fatigue,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Weight',
      label: 'Weight',
      render: (journal) => journal.weight,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Difficulty to comply with diet',
      label: 'Difficulty to comply with diet',
      render: (journal) => journal.diet_difficulty,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Water Daily Intake',
      label: 'Water Daily Intake',
      render: (journal) => journal.water,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Daily Steps',
      label: 'Daily Steps',
      render: (journal) => journal.steps,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Log added',
      label: 'Log added',
      render: (journal) => moment(journal.created_at).format('DD/MM/YYYY'),
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    }
  ];
  const keyFn = (journal) => {
    return journal.id;
  };

  return (
    <form>
      <div className="space-y-12">
        <div className="grid grid-cols-1 mt-4 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          <SleepQualityChart data={chartData} />
          <PsychoConditionChart data={chartData} />
          <FatigueChart data={chartData} />
          <StepsChart data={chartData} />
          <DietDiffcultyChart data={chartData} />
          <WaterChart data={chartData} />
          <WeightChart data={chartData} />
          <DesireTrainingChart data={chartData} />
          {/* Incluye aquí los otros gráficos */}
        </div>
      </div>
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <TableCard data={data} config={config} keyFn={keyFn} />
        </div>
      </div>
    </form>
  );
}

export default JournalManager;
