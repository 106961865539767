import AntropometryForm from "../../../components/antropometry/AntropometryForm";
import { useParams } from "react-router-dom";
import { useFetchAthleteQuery } from "../../../store";
import AntropometryTable from "../../../components/antropometry/AntropometryTable";
import AntropometryChart from "../../../components/antropometry/AntropometryChart";

export default function AntropometryManager(){
  let table;
  let { id } = useParams();
  const { data, error, isLoading} = useFetchAthleteQuery(id);
  let athlete;
  let content;
  if (isLoading) {
    content = <div> Loading...</div>;
    return content;
  }
  if (error) {
    content = <div> Error: {error.message}</div>;
    return content;
  }
  else{
    athlete = data[0];
    content = <div className="space-y-12">
      <div className="border-b border-gray-900/10 pb-12">
        <AntropometryChart athlete={athlete} />
      </div>
      <div className="border-b border-gray-900/10 pb-12">
        <AntropometryTable athlete={athlete} />
      </div>
      <div className="pb-12">
        <AntropometryForm athlete={athlete} />
      </div>
    </div>
  }
  return (
    content
  )
}