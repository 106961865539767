import SideBar from "../../components/navigation/SideBar";
import BasicActions from "../../components/headings/BasicActions";
import TableCard from "../../components/tables/TableCard";
import { NavLink, useNavigate } from "react-router-dom";
import { useFetchAthletesQuery } from "../../store";
import moment from "moment/moment";
import { useCoach } from "../../useCoach";

function Athletes(){
  const navigate = useNavigate();
  const { coach } = useCoach();
  const config = [
    {
      key: 'Id',
      label: 'Id',
      render: (athlete) => athlete.id,
      rowClassname: 'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6',
      columnClassname: 'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6',
    },
    {
      key: 'Firstname',
      label: 'Firstname',
      render: (athlete) => athlete.firstname,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Lastname',
      label: 'Lastname',
      render: (athlete) => athlete.lastname,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Email',
      label: 'Email',
      render: (athlete) => athlete.email,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Birthdate',
      label: 'Birthdate',
      render: (athlete) => moment(athlete.birthdate).format('DD/MM/YYYY'),
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Age',
      label: 'Age',
      render: (athlete) => moment().diff(athlete.birthdate, 'years'),
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Sex',
      label: 'Sex',
      render: (athlete) => athlete.sex,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Phone',
      label: 'Phone Number',
      render: (athlete) => athlete.phone_number,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Some',
      label: 'Social Media Profile',
      render: (athlete) => athlete.some_profile,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Registered',
      label: 'Registered',
      render: (athlete) => moment(athlete.created_at).format('DD/MM/YYYY'),
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Active',
      label: 'Active',
      render: (athlete) => athlete.active ? 'Active': 'Inactive',
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Manage',
      label: '',
      render: (athlete) => <NavLink to={`/manage/athlete/${athlete.id}`} className="text-indigo-600 hover:text-indigo-900">
        Manage<span className="sr-only">, {athlete.name}</span>
        </NavLink>,
      rowClassname: 'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Edit',
      label: '',
      render: (athlete) => <NavLink to={`/athlete/${athlete.id}`} className="text-indigo-600 hover:text-indigo-900">
        Edit<span className="sr-only">, {athlete.name}</span>
        </NavLink>,
      rowClassname: 'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
  ];

  const handleNew = (event)=>{
    event.preventDefault();
    navigate("/athlete/create");
  }

  const actions = [
    {label: 'New', onClick:handleNew, class: 'primary'},
  ]

  const keyFn = (athlete) => {
    return athlete.id;
  };

  const { data, error, isLoading} = useFetchAthletesQuery(coach.id);
  let table;
  if (isLoading){
    table = <div> Loading</div>
  } else if (error){
    table = <div> Errors</div>
  } else {
    table = <TableCard data={data} config={config} keyFn={keyFn} />;
  }
  return (
    <>
    <div>
      <SideBar/>
      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">
          <BasicActions object="Athletes" actions={actions}/>
          {table}
        </div>
      </main>
    </div>
    </>
  );
}

export default Athletes;