
import { useFetchAntropometryByAthleteIdQuery } from "../../store";
import StandardCompoundLinearChart from "../charts/StandardCompoundLinearChart";
import moment from "moment/moment";

export default function AntropometryChart({athlete}) {
  const config = [
    {
      key: 'Date',
      label: 'Date',
      render: (pr) => moment(pr.created_at).format('DD/MM/YYYY'),
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'biceps',
      label: 'Biceps',
      render: (pr) => pr.biceps,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'thigh',
      label: 'Thigh',
      render: (pr) => pr.thigh,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'abdomen',
      label: 'Abdomen',
      render: (pr) => pr.abdomen,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    }
  ];
  const keyFn = (record) => {
    return record.id;
  };

  const { data, error, isLoading} = useFetchAntropometryByAthleteIdQuery(athlete.id); 
  let chart1;
  let chart
  if (isLoading){
    chart1 = <div> Loading</div>
  } else if (error){
    chart1 = <div> Errors</div>
  } else {
    let chartData = data.slice().reverse();
    chart1 = <StandardCompoundLinearChart data={chartData} config={config} keyFn={keyFn} chartTitle="Circunferences variation" />;
    
  }
  return (
    <div className="space-y-12">
      <div className="grid grid-cols-2 mt-4 sm:grid-cols-2 lg:grid-cols-2 gap-6">
        <div className="p-4 bg-white rounded-lg shadow-lg">
          {chart1}
        </div>
      </div>
    </div>
  );
}