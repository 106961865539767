import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  FireIcon,
  CalendarIcon,
  CalendarDaysIcon,
  BoltIcon,
  HeartIcon,
  UserIcon
} from '@heroicons/react/24/outline'
import { useAuth0 } from "@auth0/auth0-react";
import { NavLink } from 'react-router-dom';
import { useCoach } from "../../useCoach";

const navigation = [
  { name: 'Dashboard', href: '/dashboard', icon: HomeIcon},
  { name: 'Athletes', href: '/athletes', icon: UsersIcon },
]
const config_nav = [
  { name: 'Exercises', href: '/exercises', icon: FireIcon},
  { name: 'Programs', href: '/programs', icon: CalendarDaysIcon },
  { name: 'Workouts', href: '/workouts', icon: BoltIcon }
]
const admin_nav = [
  { name: 'Coaches', href: '/coaches', icon: UserIcon},
]
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function SideBar(){
  const { coach} = useCoach();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { logout } = useAuth0();
  // Verificar si el coach tiene el rol de "admin"
  const isAdmin = coach?.role === "admin";
  const handleLogout = () => {
    logout({
      returnTo: window.location.origin,
    });
  };
  return(
    <>
    <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                  <div className="flex h-20 shrink-0 items-center">
                    <img
                      className="h-20 w-auto"
                      src="/images/logoHeadBlack.png"
                      alt="Incredibilis Fitness"
                    />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul className="-mx-2 space-y-1">
                          {navigation.map((item) => (
                            <li key={item.name}>
                              <NavLink
                                to={item.href}
                                className={({ isActive }) =>
                                  classNames(
                                    isActive
                                    ? 'bg-gray-50 text-indigo-600'
                                    : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                )}
                              >
                              {({ isActive }) => (
                                <>
                                <item.icon
                                  className={classNames(
                                    isActive ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                                    'h-6 w-6 shrink-0'
                                  )}
                                  aria-hidden="true"
                                />
                                {item.name}
                                </>
                              )}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li>
                        <div className="text-xs font-semibold leading-6 text-gray-400">Configuration</div>
                        <ul className="-mx-2 mt-2 space-y-1">
                          {config_nav.map((item) => (
                            <li key={item.name}>
                              <NavLink
                                to={item.href}
                                className={({ isActive }) =>classNames(
                                  isActive
                                    ? 'bg-gray-50 text-indigo-600'
                                    : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                )}
                              >
                                {({ isActive }) => (
                                <>
                                  <item.icon
                                    className={
                                      classNames(
                                        isActive
                                          ? 'text-indigo-600' 
                                          : 'text-gray-400 group-hover:text-indigo-600',
                                        'h-6 w-6 shrink-0'
                                      )
                                    }
                                    aria-hidden="true"
                                  />
                                {item.name}
                                </>
                              )}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </li>
                      {isAdmin && (<li>
                        <div className="text-xs font-semibold leading-6 text-gray-400">Administration</div>
                        <ul className="-mx-2 mt-2 space-y-1">
                          {admin_nav.map((item) => (
                            <li key={item.name}>
                              <NavLink
                                to={item.href}
                                className={({ isActive }) =>classNames(
                                  isActive
                                    ? 'bg-gray-50 text-indigo-600'
                                    : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                )}
                              >
                                {({ isActive }) => (
                                <>
                                  <item.icon
                                    className={
                                      classNames(
                                        isActive
                                          ? 'text-indigo-600' 
                                          : 'text-gray-400 group-hover:text-indigo-600',
                                        'h-6 w-6 shrink-0'
                                      )
                                    }
                                    aria-hidden="true"
                                  />
                                {item.name}
                                </>
                              )}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </li>)}
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
    </Transition.Root>
    <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
      
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
        <div className="flex h-32 shrink-0 items-center">
          <img
            className="h-32 w-auto"
            src="/images/logoHeadBlack.png"
            alt="Incredibilis Fitness"
          />
        </div>
        <nav className="flex flex-1 flex-col">
          <ul className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul className="-mx-2 space-y-1">
                {navigation.map((item) => (
                  <li key={item.name}>
                    <NavLink
                      to={item.href}
                      className={({ isActive }) =>
                        classNames(
                          isActive
                            ? 'bg-gray-50 text-indigo-600'
                            : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                          'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                        )
                      }
                    >
                    {({ isActive }) => (
                      <>
                        <item.icon
                          className={
                            classNames(
                              isActive
                                ? 'text-indigo-600' 
                                : 'text-gray-400 group-hover:text-indigo-600',
                              'h-6 w-6 shrink-0'
                            )
                          }
                          aria-hidden="true"
                        />
                      {item.name}
                      </>
                    )}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </li>
            <li>
              <div className="text-xs font-semibold leading-6 text-gray-400">Configuration</div>
              <ul className="-mx-2 mt-2 space-y-1">
                {config_nav.map((item) => (
                  <li key={item.name}>
                    <NavLink
                      to={item.href}
                      className={({ isActive }) =>classNames(
                        isActive
                          ? 'bg-gray-50 text-indigo-600'
                          : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                      )}
                    >
                      {({ isActive }) => (
                      <>
                        <item.icon
                          className={
                            classNames(
                              isActive
                                ? 'text-indigo-600' 
                                : 'text-gray-400 group-hover:text-indigo-600',
                              'h-6 w-6 shrink-0'
                            )
                          }
                          aria-hidden="true"
                        />
                      {item.name}
                      </>
                    )}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </li>
            {isAdmin && (<li>
              <div className="text-xs font-semibold leading-6 text-gray-400">Administration</div>
              <ul className="-mx-2 mt-2 space-y-1">
                {admin_nav.map((item) => (
                  <li key={item.name}>
                    <NavLink
                      to={item.href}
                      className={({ isActive }) =>classNames(
                        isActive
                          ? 'bg-gray-50 text-indigo-600'
                          : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                      )}
                    >
                      {({ isActive }) => (
                      <>
                        <item.icon
                          className={
                            classNames(
                              isActive
                                ? 'text-indigo-600' 
                                : 'text-gray-400 group-hover:text-indigo-600',
                              'h-6 w-6 shrink-0'
                            )
                          }
                          aria-hidden="true"
                        />
                      {item.name}
                      </>
                    )}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </li>)}
            <li className="-mx-6 mt-auto">
              <a onClick={handleLogout}
                href="#"
                className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50"
              >
                 <svg className="h-8 w-8 rounded-full bg-gray-50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 224c0 17.7 14.3 32 32 32s32-14.3 32-32l0-224zM143.5 120.6c13.6-11.3 15.4-31.5 4.1-45.1s-31.5-15.4-45.1-4.1C49.7 115.4 16 181.8 16 256c0 132.5 107.5 240 240 240s240-107.5 240-240c0-74.2-33.8-140.6-86.6-184.6c-13.6-11.3-33.8-9.4-45.1 4.1s-9.4 33.8 4.1 45.1c38.9 32.3 63.5 81 63.5 135.4c0 97.2-78.8 176-176 176s-176-78.8-176-176c0-54.4 24.7-103.1 63.5-135.4z"/></svg>
                <span className="sr-only">Logout</span>
                <span aria-hidden="true">Logout </span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
                          
    <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
      <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
      </button>
      <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">Dashboard</div>
      <a href="#" onClick={handleLogout}>
        <span className="sr-only">Log Out</span>
        <svg className="h-8 w-8 rounded-full bg-gray-50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 224c0 17.7 14.3 32 32 32s32-14.3 32-32l0-224zM143.5 120.6c13.6-11.3 15.4-31.5 4.1-45.1s-31.5-15.4-45.1-4.1C49.7 115.4 16 181.8 16 256c0 132.5 107.5 240 240 240s240-107.5 240-240c0-74.2-33.8-140.6-86.6-184.6c-13.6-11.3-33.8-9.4-45.1 4.1s-9.4 33.8 4.1 45.1c38.9 32.3 63.5 81 63.5 135.4c0 97.2-78.8 176-176 176s-176-78.8-176-176c0-54.4 24.7-103.1 63.5-135.4z"/></svg>
      </a>
    </div>
    </>
  );
}

export default SideBar;