
import { useFetchDietNeedByUserIdQuery } from "../../store";
import StandardCompoundLinearChart from "../charts/StandardCompoundLinearChart";
import moment from "moment/moment";

export default function NutritionChart({athleteId}) {
  const config1 = [
    {
      key: 'date from',
      label: 'Date From',
      render: (dietNeed) => moment(dietNeed.from).format('DD/MM/YYYY'),
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'g/kg Carbohidrates',
      label: 'g/kg Carbohidrates',
      render: (dietNeed) => dietNeed.carbs,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'g/kg Protein',
      label: 'g/kg Protein',
      render: (dietNeed) => dietNeed.protein,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'g/kg Fat',
      label: 'g/kg Fat',
      render: (dietNeed) => dietNeed.fat,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
  ];
  const config2 = [
    {
      key: 'date from',
      label: 'Date From',
      render: (dietNeed) => moment(dietNeed.from).format('DD/MM/YYYY'),
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'kcal',
      label: 'Kcal',
      render: (dietNeed) => dietNeed.kcal,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Neat',
      label: 'Neat',
      render: (dietNeed) => dietNeed.neat,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
  ];

  const keyFn = (dietNeed) => {
    return dietNeed.id;
  };
  

  const { data, error, isLoading} = useFetchDietNeedByUserIdQuery(athleteId); 
  let chart1;
  let chart2;
  if (isLoading){
    chart1 = <div> Loading</div>
  } else if (error){
    chart1 = <div> Errors</div>
  } else {
    let chartData = data.slice().reverse();
    chart1 = <StandardCompoundLinearChart data={chartData} config={config1} keyFn={keyFn} chartTitle="Macronutrients variations" />;
    chart2 = <StandardCompoundLinearChart data={chartData} config={config2} keyFn={keyFn} chartTitle="Nutrition guidelines variation" />;
    
  }
  return (
    <div className="space-y-12">
      <div className="grid grid-cols-2 mt-4 sm:grid-cols-2 lg:grid-cols-2 gap-6">
        <div className="p-4 bg-white rounded-lg shadow-lg">
          {chart1}
        </div>
        <div className="p-4 bg-white rounded-lg shadow-lg">
          {chart2}
        </div>
      </div>
    </div>
  );
}