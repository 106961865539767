function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function BasicActions({object, actions}){
  const renderedActions = actions.map((action) => {
    return <button key={action.label}
    type="button"
      className={classNames(
        action.class === "primary"
        ? 'bg-indigo-600 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
        : 'bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50',
      'mx-1 inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm'
      )}
      onClick={action.onClick}
    >
      {action.label}
    </button>
  });
  return (
    <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
      <h3 className="text-base font-semibold leading-6 text-gray-900">{object}</h3>
      <div className="mt-3 flex sm:ml-4 sm:mt-0">
        {renderedActions}
      </div>
    </div>
  )
}

export default BasicActions