import SideBar from "../../components/navigation/SideBar";
import TableCard from "../../components/tables/TableCard";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchExerciseQuery, useUpdateExerciseMutation } from "../../store";
import StandardForm from "../../components/forms/StandardForm";

function EditExerciseForm(){
  let { id } = useParams();
  const navigate = useNavigate();
  const { data, error, isLoading} = useFetchExerciseQuery(id);
  const [editExercise, results] = useUpdateExerciseMutation();
  const config = [
    {
      id:'name',
      label: 'Exercise name',
      type: 'shortText',
      placeholder: 'Bench Press',
      size: 2
    },
    {
      id:'video_tutorial',
      label: 'Link to video',
      type: 'shortText',
      placeholder: 'Video tutorial link',
      size: 2
    },
    {
      id:'description',
      label: 'Exercise Description',
      type: 'text',
      placeholder: 'Write how to conduct the exercise.',
      size: 'full'
    },
  ];
  const handleEdit = (e) =>{
    e.preventDefault();
    
    const submitData = config.reduce((o, input) => ({ ...o, [input.id]: e.target[input.id].value}), {});
    editExercise({id, submitData});
    navigate("/exercises");
  };
  let meta;
  let basicDetailsForm;
  if (isLoading){
    basicDetailsForm = <div> Loading</div>
  } else if (error){
    basicDetailsForm = <div> Errors</div>
  } else {
    let exercise = data[0];
    meta = {
      object: "Exercise",
      action: "Edit details of "+exercise.name+".",
      description: "Edit details of the exercise.",
    };
    basicDetailsForm = <StandardForm meta={meta} config={config} colsForm={1} data={exercise} onSubmit={handleEdit}/>;
  }

  return (
    <>
    <div>
      <SideBar/>
      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">
          {basicDetailsForm}
        </div>
      </main>
    </div>
    </>
  );
}

export default EditExerciseForm;