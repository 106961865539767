import SideBar from "../../components/navigation/SideBar";
import { useNavigate, } from "react-router-dom";
import { addExerciseToWorkout, removeExerciseFromWorkout, changeOrderUp, useCreateWorkoutMutation, changeOrderDown, addSet, removeSet, useCreateWorkoutExerciseMutation, useCreateSetObjectiveMutation, clean } from "../../store";
import StandardForm from "../../components/forms/StandardForm";
import SearchExercise from "../../components/workout/SearchExercise";
import { useDispatch, useSelector } from "react-redux";
import ExercisesInWorkoutList from "../../components/workout/ExercisesInWorkoutList";
import { useEffect } from "react";
import { useCoach } from "../../useCoach";

function CreateWorkoutForm(){
  const { coach } = useCoach();
  const dispatch = useDispatch();
  const { exercisesInWorkout } = useSelector((state) => {
    return {exercisesInWorkout: state.exercisesInWorkout.data};
  });
  const navigate = useNavigate();
  const [createWorkout] = useCreateWorkoutMutation();
  const [createWorkoutExercise] = useCreateWorkoutExerciseMutation();
  const [createSetObjective] = useCreateSetObjectiveMutation();
  const meta = {
      object: "Workout",
      action: "Create new workout",
      description: "Create a new workout record.",
    };
  const config = [
    {
      id:'name',
      label: 'Workout name',
      type: 'shortText',
      placeholder: 'Microcycle A1',
      size: 2
    }
  ];

  const handleCreate = async (e)=> {
    e.preventDefault();
    const submitData = config.reduce((o, input) => ({ 
      ...o, 
      [input.id]: e.target[input.id].value,
      coach: coach.id
    }), {});
    const {data} = await createWorkout(submitData);
    const createdWorkout = data[0];
    exercisesInWorkout.forEach(async(exercise)=>{
      let {data} = await createWorkoutExercise({ workout: createdWorkout.id, exercise: exercise.id, order: exercise.order, note: exercise.note });
      let workoutExercise = data[0];
      exercise.sets.forEach((set)=>{
        createSetObjective({
          workout_exercise: workoutExercise.id,
          index: set.index,
          kg: set.kg,
          reps: set.reps,
          rpe: set.rpe,
          rir: set.rir,
          tut: set.tut,
          time: set.time,
          rest: set.rest,
          note: set.note,
          percentage_rm: set.percentage_rm,
        });
      });
    });
    dispatch(clean());
    navigate("/workouts");
  }
  const handleAddExercise = (exerciseId)=>{
    dispatch(addExerciseToWorkout({exerciseId:exerciseId, order:exercisesInWorkout.length+1}));
  };
  const handleExerciseRemove = (key)=>{
    dispatch(removeExerciseFromWorkout(key));
  }
  const handleAddSet = (key)=>{
    dispatch(addSet(key));
  }
  const handleRemoveSet = (key,setId)=>{
    dispatch(removeSet({exerciseKey:key, setId:setId}));
  }
  const handleChangeOrderUp = (key)=>{
    dispatch(changeOrderUp(key));
  }
  const handleChangeOrderDown = (key)=>{
    dispatch(changeOrderDown(key));
  }
  
  useEffect(()=>{
    dispatch(clean());
  }, [dispatch]);
  return (
    <>
    <div>
      <SideBar/>
      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">
          <StandardForm meta={meta} config={config} colsForm={1} onSubmit={handleCreate}/>
        </div>
        <div className="mx-auto max-w-12xl px-4 py-2 sm:px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              <div className="lg:col-start-3 lg:row-end-1">
                <SearchExercise onAddExercise={handleAddExercise}/>
              </div>

              {/*Left */}
              <div className="-mx-4 px-4 py-8 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-2 xl:pb-10 xl:pt-8">
                <h2 className="text-base pb-6 font-semibold leading-6 text-gray-900">Exercises in workout</h2>
                <ExercisesInWorkoutList exercises={exercisesInWorkout} onClickRemove={handleExerciseRemove} onClickAddSet={handleAddSet} onClickRemoveSet={handleRemoveSet} onClickOrderUp={handleChangeOrderUp} onClickOrderDown={handleChangeOrderDown}/>
              </div>
            </div>
          </div>
      </main>
    </div>
    </>
  );
}

export default CreateWorkoutForm;