import { useCreateAntropometryMutation, useCreateRecordMutation } from "../../store";
import StandardForm from "../forms/StandardForm";

export default function AntropometryForm({athlete}){
  const [createPR] = useCreateAntropometryMutation();
  const handleCreate = (e)=>{
    e.preventDefault();
    const submitData = config.reduce((o, input) => ({ ...o, [input.id]: e.target[input.id].value}), {});
    const createData = {...submitData, athlete:athlete.id};
    createPR(createData);
  }
  const meta = {
    object: "Antropometry Register Form",
    action: "Register new antropometric record",
    description: "Create a new antropometry entry for this athlete.",
  };
  let config = [
    {
      id:'biceps',
      label: 'Biceps circunference',
      type: 'unit',
      unit: 'cm',
      size: 2
    },
    {
      id:'thigh',
      label: 'Thigh circunference',
      type: 'unit',
      unit: 'cm',
      size: 2
    },
    {
      id:'abdomen',
      label: 'Abdomen circunference',
      type: 'unit',
      unit: 'cm',
      size: 2
    }
  ];
  return(
    <StandardForm breadcrumb={false} meta={meta} config={config} onSubmit={handleCreate}/>
  );
}