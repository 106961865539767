import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import BreadCrumb from "../../components/headings/BreadCrumb";
import SideBar from "../../components/navigation/SideBar";
import JournalManager from "./manager/JournalManager";
import ProgramManager from "./manager/ProgramManager";
import NutritionManager from "./manager/NutritionManager";
import { PencilSquareIcon, RocketLaunchIcon, BeakerIcon, ScaleIcon, 
  AdjustmentsVerticalIcon, VariableIcon, AcademicCapIcon } from "@heroicons/react/24/outline";
import PlicometryManager from "./manager/PlicometryManager";
import PRManager from "./manager/PRManager";
import AntropometryManager from "./manager/AntropometryManager";
import FMSManager from "./manager/FMSManager";
import { useFetchAthleteQuery } from "../../store";
import { PageLoader } from "../../components/page-loader";
import { useCoach } from "../../useCoach";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function ManageAthlete(){
  let { id } = useParams();
  const { coach } = useCoach();
  const { data, error, isLoading } = useFetchAthleteQuery(id);
  const [panel, setPanel] = useState(<JournalManager />);
  const [tabs, setTabs] = useState([
    { name: 'Journal', href: '#journal', component: <JournalManager />, icon: PencilSquareIcon, current: true },
    { name: 'Training', href: '#training', component: <ProgramManager />, icon: RocketLaunchIcon, current: false },
    { name: 'Personal Records', href: '#prs', component: <PRManager />, icon: AdjustmentsVerticalIcon, current: false },
    { name: 'Nutrition', href: '#nutrition', component: <NutritionManager />, icon: BeakerIcon, current: false },
    { name: 'Plicometry', href: '#plicometry', component: <PlicometryManager />, icon: ScaleIcon, current: false },
    { name: 'Antropometry', href: '#antropometry', component: <AntropometryManager />, icon: VariableIcon, current: false },
    { name: 'FMS', href: '#fms', component: <FMSManager />, icon: AcademicCapIcon, current: false },
  ]);

  useEffect(() => {
    const savedTabHref = localStorage.getItem('selectedTabHref');
    if (savedTabHref) {
      const selectedTab = tabs.find((tab) => tab.href === savedTabHref);
      if (selectedTab) {
        setTabs(tabs.map((tab) => ({
          ...tab,
          current: tab.href === savedTabHref
        })));
        setPanel(selectedTab.component);
      }
    }
  }, []);

  const breadCrumbMeta = {
    object: "Athlete",
    action: "Manage Athlete",
    description: "Manage the training of the athlete.",
  };

  const onSwitchTab = (href) => {
    const selectedTab = tabs.find((tab) => tab.href === href);
    const updatedTabs = tabs.map((tab) => ({
      ...tab,
      current: tab.href === href
    }));
    setTabs(updatedTabs);
    setPanel(selectedTab.component);
    localStorage.setItem('selectedTabHref', href);
  };
  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  } else if (error) {
    return "Error";
  } else {
    if(data[0].coach !== coach.id){
      return "Naughty ladyboy";
    }
    else{
      return (
        <>
        <div>
          <SideBar/>
          <main className="py-10 lg:pl-72">
            <div className="px-4 sm:px-6 lg:px-8">
              <BreadCrumb meta={breadCrumbMeta}/>
              <div>
                <div className="sm:hidden">
                  <label htmlFor="tabs" className="sr-only">
                    Select a tab
                  </label>
                  <select
                    id="tabs"
                    name="tabs"
                    className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                    defaultValue={tabs.find((tab) => tab.current).name}
                    onChange={(e) => onSwitchTab(e.target.value)}
                  >
                    {tabs.map((tab) => (
                      <option key={tab.name} value={tab.href}>{tab.name}</option>
                    ))}
                  </select>
                </div>
                <div className="hidden sm:block">
                  <div className="border-b border-gray-200">
                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                      {tabs.map((tab) => (
                        <a
                          key={tab.name}
                          href={tab.href}
                          onClick={() => onSwitchTab(tab.href)}
                          className={classNames(
                            tab.current
                              ? 'border-indigo-500 text-indigo-600'
                              : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                            'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium'
                          )}
                          aria-current={tab.current ? 'page' : undefined}
                        >
                          <tab.icon
                            className={classNames(
                              tab.current ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500',
                              '-ml-0.5 mr-2 h-5 w-5'
                            )}
                            aria-hidden="true"
                          />
                          <span>{tab.name}</span>
                        </a>
                      ))}
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-4 sm:px-6 lg:px-8">
              {panel}
            </div>
          </main>
        </div>
        </>
      );
    }
  }
}

export default ManageAthlete;
