import React from 'react';
import SideBar from "../../components/navigation/SideBar";
import BasicActions from "../../components/headings/BasicActions";
import WorkoutsTable from '../../components/tables/WorkoutsTable';
import { useNavigate } from "react-router-dom";
import { useDeleteWorkoutMutation, useFetchWorkoutsQuery } from "../../store";
import { useWorkoutService } from "../../services/workout.service";
import { useCoach } from "../../useCoach";

function Workouts() {
  const navigate = useNavigate();
  const { coach } = useCoach();
  const [deleteExercise] = useDeleteWorkoutMutation();
  const { handleDuplicate } = useWorkoutService();
  const { data, error, isLoading, refetch } = useFetchWorkoutsQuery(coach.id);

  const handleDelete = (e, workoutId) => {
    e.preventDefault();
    deleteExercise(workoutId);
  };

  const handleNew = (event) => {
    event.preventDefault();
    navigate("/workout/create");
  };

  const actions = [
    { label: 'New', onClick: handleNew, class: 'primary' },
  ];

  let table;
  if (isLoading) {
    table = <div>Loading</div>;
  } else if (error) {
    table = <div>Errors</div>;
  } else {
    table = <WorkoutsTable data={data} handleDuplicate={handleDuplicate} handleDelete={handleDelete} refetch={refetch} />;
  }

  return (
    <>
      <div>
        <SideBar />
        <main className="py-10 lg:pl-72">
          <div className="px-4 sm:px-6 lg:px-8">
            <BasicActions object="Workouts" actions={actions} />
            {table}
          </div>
        </main>
      </div>
    </>
  );
}

export default Workouts;
