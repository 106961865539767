import React from 'react';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend
);


const StepsChart = ({ data }) => {
  const chartData = {
    labels: data.map(journal => moment(journal.date).format('DD/MM/YYYY')),
    datasets: [{
      label: 'Steps',
      data: data.map(journal => {
        switch (journal.steps) {
          default: return journal.steps;
        }
      }),
      fill: false,
      borderColor: 'rgb(106, 90, 205)',
      tension: 0.1,
       cubicInterpolationMode: 'monotone'
    }],
  };

  const options = {
    spanGaps: true,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: 'Steps',
        },
        min: 0,
        max: 25000,
      },
      x: {
        title: {
          display: true,
          text: 'Date',
        }
      }
    }
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-lg">
      <Line data={chartData} options={options} />
    </div>
  );
};

export default StepsChart;
