import React, { useState } from 'react';
import TrainingTable from './TrainingTable';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const TrainingTableList = ({ programWorkouts, programName }) => {
  const [allVisible, setAllVisible] = useState(true);
  const [visibleTables, setVisibleTables] = useState(
    programWorkouts.reduce((acc, workout) => {
      acc[workout.id] = true;
      return acc;
    }, {})
  );

  const toggleAllVisibility = (event) => {
    event.preventDefault();
    const newVisibility = !allVisible;
    setAllVisible(newVisibility);
    setVisibleTables(
      programWorkouts.reduce((acc, workout) => {
        acc[workout.id] = newVisibility;
        return acc;
      }, {})
    );
  };

  const toggleVisibility = (event, id) => {
    event.preventDefault();
    setVisibleTables({
      ...visibleTables,
      [id]: !visibleTables[id]
    });
  };

  const downloadPDF = async () => {
    const doc = new jsPDF('p', 'pt', 'a4');
    const margin = 20;
    const pageHeight = doc.internal.pageSize.getHeight();
    let currentHeight = margin + 120; // Altura inicial después del logo
  
    // Logo (deberías ajustar la ruta del logo aquí)
    const logoSrc = '/images/logoHeadBlack.png';
    const logo = new Image();
    logo.src = logoSrc;
  
    await new Promise((resolve) => {
      logo.onload = () => {
        const imgWidth = 100;
        const imgHeight = 100;
        const pageWidth = doc.internal.pageSize.getWidth();
        doc.addImage(
          logo,
          'PNG',
          (pageWidth - imgWidth) / 2,
          margin,
          imgWidth,
          imgHeight
        );
        resolve();
      };
    });
    const pageWidth = doc.internal.pageSize.getWidth();
    const programTextWidth = doc.getTextWidth(programName);
    doc.text(programName, (pageWidth - programTextWidth) / 2, 125);
    for (let i = 0; i < programWorkouts.length; i++) {
      if (visibleTables[programWorkouts[i].id]) {
        const input = document.getElementById(`table-${programWorkouts[i].id}`);
        const canvas = await html2canvas(input, { scale: 2 });
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = doc.internal.pageSize.getWidth() - margin * 2;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
        // Verifica si la imagen cabe en la página actual
        if (currentHeight + imgHeight > pageHeight - margin) {
          doc.addPage();
          currentHeight = margin + 20; // Resetear altura para la nueva página
        }
  
        doc.addImage(imgData, 'PNG', margin, currentHeight, imgWidth, imgHeight);
        currentHeight += imgHeight + 20; // Agregar un pequeño margen entre tablas
      }
    }
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(8);
    var copyrightText ="Incredibilis 4 Fitness - www.incredibilis.fitness";
    const copyrightTextWidth = doc.getTextWidth(copyrightText);
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(copyrightText, (pageWidth - copyrightTextWidth) / 2, doc.internal.pageSize.getHeight() - margin);
      doc.text(`${i} / ${pageCount}`, doc.internal.pageSize.getWidth() - margin - 20,doc.internal.pageSize.getHeight() - margin);
    }
    doc.save(programName+'.pdf');
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-3xl font-bold">Program Workouts</h1>
        <div className="flex">
          <button
            onClick={(event) => toggleAllVisibility(event)}
            className="bg-indigo-500 hover:bg-indigo-700 text-white py-2 px-4 rounded mr-2"
          >
            {allVisible ? 'Collapse all' : 'Expand all'}
          </button>
          <button
            onClick={downloadPDF}
            className="bg-indigo-500 hover:bg-indigo-700 text-white py-2 px-4 rounded"
          >
            Download PDF
          </button>
        </div>
      </div>
      {programWorkouts.map((workout) => (
        <div key={workout.id} id={`table-${workout.id}`} className="mb-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold">{workout.name}</h2>
            <button
              onClick={(event) => toggleVisibility(event, workout.id)}
              className="bg-indigo-500 hover:bg-indigo-700 text-white py-2 px-4 rounded"
            >
              {visibleTables[workout.id] ? '-' : '+'}
            </button>
          </div>
          {visibleTables[workout.id] && <TrainingTable trainingData={workout} />}
        </div>
      ))}
    </div>
  );
};

export default TrainingTableList;
