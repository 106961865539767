
import { useFetchPlicometryByAtheleteIdQuery } from "../../store";
import StandardCompoundLinearChart from "../charts/StandardCompoundLinearChart";
import { calculatePlicometry } from "../../services/plicometry.service";
import moment from "moment/moment";

export default function PlicoChart({athlete}) {
  const config1 = [
    {
      key: 'date',
      label: 'Date',
      render: (plico) => moment(plico.date).format('DD/MM/YYYY'),
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'chest',
      label: 'Chest',
      render: (plico) => plico.chest,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'midaxilla',
      label: 'Midaxilla',
      render: (plico) => plico.midaxilla,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'abdomen',
      label: 'Abdomen',
      render: (plico) => plico.abdomen,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'suprailiac',
      label: 'Suprailiac',
      render: (plico) => plico.suprailiac,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'quadriceps',
      label: 'Quadriceps/Thigh',
      render: (plico) => plico.quadriceps,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'subscapular',
      label: 'Subscapular',
      render: (plico) => plico.subscapular,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'triceps',
      label: 'Triceps',
      render: (plico) => plico.triceps,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    }
  ];
  const config2 = [
    {
      key: 'date',
      label: 'Date',
      render: (plico) => moment(plico.date).format('DD/MM/YYYY'),
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'weight',
      label: 'Weight',
      render: (plico) => plico.weight,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'height',
      label: 'Height',
      render: (plico) => plico.height,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'lbm',
      label: 'LBM',
      render: (plico) => {
        const calculated = calculatePlicometry(plico,athlete, plico.method);
        return (
            calculated.lbm
        );
      },
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'fat',
      label: 'Fat',
      render: (plico) => {
        const calculated = calculatePlicometry(plico,athlete, plico.method);
        return (
            calculated.bodyFat
        );
      },
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    }
  ];
  const keyFn = (record) => {
    return record.id;
  };

  const { data, error, isLoading} = useFetchPlicometryByAtheleteIdQuery(athlete.id); 
  let chart1;
  let chart2
  if (isLoading){
    chart1 = <div> Loading</div>
  } else if (error){
    chart1 = <div> Errors</div>
  } else {
    let chartData = data.slice().reverse();
    chart1 = <StandardCompoundLinearChart data={chartData} config={config1} keyFn={keyFn} chartTitle="Plicometry Variation" />;
    chart2 = <StandardCompoundLinearChart data={chartData} config={config2} keyFn={keyFn} chartTitle="Body Composition Variation" />;
  }
  return (
    <div className="space-y-12">
      <div className="grid grid-cols-2 mt-4 sm:grid-cols-2 lg:grid-cols-2 gap-6">
        <div className="p-4 bg-white rounded-lg shadow-lg">
          {chart1}
        </div>
        <div className="p-4 bg-white rounded-lg shadow-lg">
          {chart2}
        </div>
      </div>
    </div>
  );
}