import NumberMealsSlider from '../nutrition/NumberMealsSlider';
import ToggleWithLabel from '../forms/inputs/ToggleWithLabel';
import RadioGroupInput from '../nutrition/RadioGroupInput';
import DropDown from '../forms/inputs/DropDown';
import TagListInput from '../forms/inputs/TagListInput';
import { changeNumberMeals, changeNumberSnacks, changePreworkout, changePostworkout, changeRestriction,
  changeMealLanguage, changeAllergies, changeIncludedFoods, changeExcludedFoods, changeComments
} from "../../store";
import { useDispatch, useSelector } from 'react-redux';


export default function MealPlanForm ({dietNeed}){
  const dispatch = useDispatch();
  const nutrition_requirements = [
    {name: "Carbohidrates"},
    {name: "Proteins"},
    {name: "Lipids"},
    {name: "KCal"},
  ];
  function dietObject(id, calories,carbs, protein, fat, meals ) {
    this.id = id;
    this.calories = calories;
    this.carbs = carbs;
    this.protein = protein;
    this.fat = fat;
    this.meals = meals; //array of mainObject
  }
  function mealObject(id, name, calories,carbs, protein, fat, type, foods ) {
    this.id = id;
    this.name = name; //name of the meal or artistic name
    this.calories = calories;
    this.carbs = carbs;
    this.protein = protein;
    this.fat = fat;
    this.type = type; //Main meal, snack meal, preworkout or postworkout 
    this.foods = foods; //array of foodObject
  }
  function foodObject(name, grams, calories,carbs, protein, fat){
    this.name = name;
    this.grams = grams; //grams of this food
    this.calories = calories; //calories of this food given the grams
    this.carbs = carbs; //carbs of this food given the grams
    this.protein = protein; //protein of this food given the grams
    this.fat = fat; //fat of this food given the grams
  }

  const dietTypes = [
    { id: 1, title: 'Anything', description: ''},
    { id: 2, title: 'Paleo', description: ''},
    { id: 3, title: 'Vegetarian', description: ''},
    { id: 4, title: 'Vegan', description: '' },
    { id: 5, title: 'Ketogenic', description: '' },
    { id: 6, title: 'Mediterranean', description: '' },
  ]
  const defaultIncludedFoods = useSelector(({mealPlanForm: {includedFoods}}) => {
    return includedFoods;
  });
  const defaultExcludedFoods = useSelector(({mealPlanForm: {excludedFoods}}) => {
    return excludedFoods;
  });

  const handleNumberMealsChange = (rangeValue) =>{
    var rangeInt = parseInt(rangeValue);
    dispatch(changeNumberMeals(rangeInt));
  };
  const handleNumberSnacksChange = (rangeValue) =>{
    var rangeInt = parseInt(rangeValue);
    dispatch(changeNumberSnacks(rangeInt));
  };
  const handlePreWorkoutChange = (value) =>{
    dispatch(changePreworkout(value));
  };
  
  const handlePostWorkoutChange = (value) =>{
    dispatch(changePostworkout(value));
  };
  
  const handleRestrictionIndexChange = (item) =>{
    dispatch(changeRestriction(item));
  };
  
  const handleMealLanguageChange = (item) =>{
    dispatch(changeMealLanguage(item));
  };
  const handleAllergiesChange = (item) =>{
    dispatch(changeAllergies(item));
  };
  
  const handleFoodsChange = (item) =>{
    dispatch(changeIncludedFoods(item));
  };

  const handleExcludeFoodsChange = (item) =>{
    dispatch(changeExcludedFoods(item));
  };

  const handleCommentsChange = (event) =>{
    dispatch(changeComments(event.target.value));
  };

  return(
    <div className="grid grid-rows-1 grid-cols-5 grid-flow-col gap-2">
      <div className="row-span-3">
        <div className="">
          <table className="min-w-fit divide-y divide-gray-300">
            <thead>
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                  Macros
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Daily Average
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  g/kg body weight
                </th>
                
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
            {nutrition_requirements.map((req) => (
              <tr key={req.name}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-left font-medium text-gray-900 sm:pl-0">
                  {req.name}
                </td> 
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {req.name === "KCal" ? dietNeed.kcal: ""}
                  {req.name === "Carbohidrates"? (dietNeed.carbs*dietNeed.weight).toFixed(2): ""}
                  {req.name === "Proteins" ? dietNeed.protein*dietNeed.weight: ""}
                  {req.name === "Lipids" ? dietNeed.fat*dietNeed.weight: ""}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {req.name === "Carbohidrates" ? dietNeed.carbs: ""}
                  {req.name === "Proteins" ? dietNeed.protein: ""}
                  {req.name === "Lipids" ? dietNeed.fat: ""}
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
        <div className="">
          <label htmlFor="objective" className="block text-sm text-left font-medium leading-6 text-gray-900">
            Meal Plan Language
          </label>
          <div className="">
            <DropDown id="language"
              options={[
                { id: 1, value:'English', label: 'English'},
                { id: 2, value:'Spanish', label: 'Spanish'},
                { id: 3, value:'French', label: 'French'},
                { id: 4, value:'German', label: 'German'},
                { id: 5, value:'Danish', label: 'Danish'},
              ]}
              onChange={handleMealLanguageChange}
            />
          </div>
        </div>
      </div>
      <div className="col-span-4">
        <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid grid-cols-6 gap-x-6 gap-y-8">
              <div className="col-span-2">
                <label htmlFor="objective" className="block text-sm text-left font-medium leading-6 text-gray-900">
                  Number of main meals
                </label>
                <div className="">
                  <NumberMealsSlider elementID="numberMeals"
                    min={1}
                    max={7}
                    unit="main meals"
                    initialValue={3}
                    onChange={handleNumberMealsChange}
                  />
                </div>
              </div>
              <div className="col-span-2">
                <label htmlFor="objective" className="block text-sm text-left font-medium leading-6 text-gray-900">
                  Number of snack breaks
                </label>
                <div className="">
                  <NumberMealsSlider elementID="numberSnacks"
                    min={0}
                    max={7}
                    unit="snack breaks"
                    initialValue={0}
                    onChange={handleNumberSnacksChange}
                  />
                </div>
              </div>
              <div className="">
                <div className="">
                  <ToggleWithLabel onChange={handlePreWorkoutChange} mainLabel="Preworkout"/>
                </div>
              </div>
              <div className="">
                <div className="">
                  <ToggleWithLabel onChange={handlePostWorkoutChange} mainLabel="Postworkout"/>
                </div>
              </div>
              <div className="col-span-2">
                <RadioGroupInput
                  onChange={handleRestrictionIndexChange} data={dietTypes} label="Select preferred diet type"
                />
              </div>
              <div className="col-span-2">
                <TagListInput
                  onTagsChange={handleAllergiesChange}
                  label="Add allergies"
                />
              </div>
              <div className="col-span-2">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Additional comments
                </label>
                <div className="mt-2">
                  <textarea
                    id="comments"
                    name="comments"
                    rows={3}
                    onChange={handleCommentsChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="mt-3 text-sm leading-6 text-gray-600">Any additional comments for creating the meal</p>
              </div>
              <div className="col-span-2">
                <TagListInput
                  onTagsChange={handleFoodsChange}
                  initialTags={defaultIncludedFoods}
                  label="Include specific foods"
                />
              </div>
              <div className="col-span-2">
                <TagListInput
                  onTagsChange={handleExcludeFoodsChange}
                  initialTags={defaultExcludedFoods}
                  label="Exclude specific foods"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}