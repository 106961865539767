import React, { createContext } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useFetchCoachByAuthIdQuery } from "./store";

// Crear el contexto
export const CoachContext = createContext();

// Crear el provider que depende de UserContext
export const CoachProvider = ({ children }) => {
    const { user } = useAuth0();
    const { data: coachData, error, isLoading} = useFetchCoachByAuthIdQuery(user?.sub, {
      skip: !user, // Omite la query si no hay usuario
    });

    // Selecciona el primer coach del array si existe
    const coach = coachData?.[0] || null;

    return (
        <CoachContext.Provider value={{ coach, error, isLoading }}>
            {children}
        </CoachContext.Provider>
    );
};
