import React from 'react';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend
);


const DesireTrainingChart = ({ data }) => {
  const chartData = {
    labels: data.map(journal => moment(journal.date).format('DD/MM/YYYY')),
    datasets: [{
      label: 'Desire for training',
      data: data.map(journal => {
        switch (journal.desire_training) {
          case 'Super negative': return 1;
          case 'Negative': return 2;
          case 'Normal': return 3;
          case 'Good': return 4;
          case 'Optimal': return 5;
          default: return null;
        }
      }),
      fill: false,
      borderColor: 'rgb(147, 112, 219)',
      tension: 0.1,
       cubicInterpolationMode: 'monotone'
    }],
  };

  const options = {
    spanGaps: true,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: 'Desire for training',
        },
        min: 1,
        max: 5,
        ticks: {
          callback: function(value, index, values) {
            switch (value) {
              case 1: return 'Super Negative';
              case 2: return 'Negative';
              case 3: return 'Normal';
              case 4: return 'Good';
              case 5: return 'Optimal';
              default: return '';
            }
          }
        }
      },
      x: {
        title: {
          display: true,
          text: 'Date',
        }
      }
    }
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-lg">
      <Line data={chartData} options={options} />
    </div>
  );
};

export default DesireTrainingChart;
