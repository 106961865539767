import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { PageLoader } from "./components/page-loader";
import { AuthenticationGuard } from "./components/authentication-guard";
import HomePage from "./pages/home-page";
import Dashboard from "./pages/Dashboard";
import Athletes from "./pages/athlete/Athletes";
import CreateAthleteForm from "./pages/athlete/CreateAthleteForm";
import EditAthleteForm from "./pages/athlete/EditAthleteForm";
import Exercises from "./pages/exercise/Exercises";
import CreateExerciseForm from "./pages/exercise/CreateExerciseForm";
import EditExerciseForm from "./pages/exercise/EditExerciseForm";
import Programs from "./pages/program/Programs";
import CreateProgram from "./pages/program/CreateProgram";
import Workouts from "./pages/workout/Workouts";
import CreateWorkoutForm from "./pages/workout/CreateWorkoutForm";
import EditWorkoutForm from "./pages/workout/EditWorkoutForm";
import EditProgram from "./pages/program/EditProgram";
import ManageAthlete from "./pages/athlete/ManageAthlete";
import { useCoach } from "./useCoach";

export const App = () => {
  const { isLoading: authLoading } = useAuth0();
  const { coach, isLoading: coachLoading } = useCoach();

  // Mostrar loader mientras se cargan los datos del coach
  if (authLoading || coachLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  // Verificar si el coach tiene el rol de "admin"
  const isAdmin = coach?.role === "admin";
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      {/* Rutas para el rol de "admin" */}
      {isAdmin && (
        <>
          <Route path="/dashboard" element={<AuthenticationGuard component={Dashboard} />} />
          <Route path="/athletes" element={<AuthenticationGuard component={Athletes} />} />
          <Route path="/athlete/create" element={<AuthenticationGuard component={CreateAthleteForm} />} />
          <Route path="/athlete/:id" element={<AuthenticationGuard component={EditAthleteForm} />} />
          <Route path="/manage/athlete/:id" element={<AuthenticationGuard component={ManageAthlete} />} />
          <Route path="/exercises" element={<AuthenticationGuard component={Exercises} />} />
          <Route path="/exercise/create" element={<AuthenticationGuard component={CreateExerciseForm} />} />
          <Route path="/exercise/:id" element={<AuthenticationGuard component={EditExerciseForm} />} />
          <Route path="/programs" element={<AuthenticationGuard component={Programs} />} />
          <Route path="/program/create" element={<AuthenticationGuard component={CreateProgram} />} />
          <Route path="/program/:id" element={<AuthenticationGuard component={EditProgram} />} />
          <Route path="/workouts" element={<AuthenticationGuard component={Workouts} />} />
          <Route path="/workout/create" element={<AuthenticationGuard component={CreateWorkoutForm} />} />
          <Route path="/workout/:id" element={<AuthenticationGuard component={EditWorkoutForm} />} />
        </>
      )}

      {/* Rutas para otros roles (no "admin") */}
      {!isAdmin && (
         <>
         <Route path="/dashboard" element={<AuthenticationGuard component={Dashboard} />} />
         <Route path="/athletes" element={<AuthenticationGuard component={Athletes} />} />
         <Route path="/athlete/create" element={<AuthenticationGuard component={CreateAthleteForm} />} />
         <Route path="/athlete/:id" element={<AuthenticationGuard component={EditAthleteForm} />} />
         <Route path="/manage/athlete/:id" element={<AuthenticationGuard component={ManageAthlete} />} />
         <Route path="/exercises" element={<AuthenticationGuard component={Exercises} />} />
         <Route path="/exercise/create" element={<AuthenticationGuard component={CreateExerciseForm} />} />
         <Route path="/exercise/:id" element={<AuthenticationGuard component={EditExerciseForm} />} />
         <Route path="/programs" element={<AuthenticationGuard component={Programs} />} />
         <Route path="/program/create" element={<AuthenticationGuard component={CreateProgram} />} />
         <Route path="/program/:id" element={<AuthenticationGuard component={EditProgram} />} />
         <Route path="/workouts" element={<AuthenticationGuard component={Workouts} />} />
         <Route path="/workout/create" element={<AuthenticationGuard component={CreateWorkoutForm} />} />
         <Route path="/workout/:id" element={<AuthenticationGuard component={EditWorkoutForm} />} />
       </>
      )}

    </Routes>
  );
};
