import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const authHeaders = { 
  apikey: process.env.REACT_APP_SUPABASE_APIKEY,
  authorization: process.env.REACT_APP_SUPABASE_AUTH_TOKEN,
}; 
const programAthleteApi = createApi({
  reducerPath: 'programsAthletes',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://kzsfryzkbjkimvzprysp.supabase.co/rest/v1/'
  }),
  endpoints(builder){
    return {
      fetchProgramsAthletes: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'ProgramAthlete'}];
        },
        query: () => {
          return {
            url:'/program_athlete',
            params:{
              // userId: user.id
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      createProgramAthlete: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'ProgramAthlete'}];
        },
        query: (data) => {
          return {
            url:'/program_athlete',
            body:data,
            method: 'POST',
            headers: {
              ...authHeaders,
              "Prefer": "return=representation",
              "Content-Type": 'application/json'
            },
          }
        }
      }),
      updateProgramAthlete: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'ProgramAthlete'}];
        },
        query: ({id,submitData}) => {
          console.log(id);
          return {
            url:'/program_athlete',
            params:{
              id:"eq."+id
            },
            body:submitData,
            method: 'PATCH',
            headers: {
              ...authHeaders,
              "Prefer": "return=representation",
              "Content-Type": 'application/json'
            },
          }
        }
      }),
      deleteProgramAthlete: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'ProgramAthlete'}];
        },
        query: (id) => {
          return {
            url:'/program_athlete',
            params:{
              id:"eq."+id
            },
            
            method: 'DELETE',
            headers: authHeaders
          }
        }
      }),
      fetchProgramAthlete: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'ProgramAthlete'}];
        },
        query: (athleteId) => {
          return {
            url:'/program_athlete',
            params:{
              athlete:"eq."+athleteId,
              select: "*,program(*)"
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
    }
  }
});

export const { useFetchProgramsAthletesQuery, useCreateProgramAthleteMutation, useUpdateProgramAthleteMutation, 
  useDeleteProgramAthleteMutation, useFetchProgramAthleteQuery } = programAthleteApi;
export { programAthleteApi };