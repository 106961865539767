import { useCreateRecordMutation } from "../../store";
import StandardForm from "../forms/StandardForm";

export default function PRForm({athlete}){
  const [createPR] = useCreateRecordMutation();
  const handleCreate = (e)=>{
    e.preventDefault();
    const submitData = config.reduce((o, input) => ({ ...o, [input.id]: e.target[input.id].value}), {});
    const createData = {...submitData, athlete:athlete.id};
    createPR(createData);
  }
  const meta = {
    object: "Personal Record Form",
    action: "Register new Personal Record",
    description: "Create a new PR.",
  };
  let config = [
    {
      id:'bench_press',
      label: 'Bench Press',
      type: 'unit',
      unit: 'kg',
      size: 2
    },
    {
      id:'squat',
      label: 'Squat',
      type: 'unit',
      unit: 'kg',
      size: 2
    },
    {
      id:'deadlift',
      label: 'Deadlift',
      type: 'unit',
      unit: 'kg',
      size: 2
    },
    {
      id:'military_press',
      label: 'Military Press',
      type: 'unit',
      unit: 'kg',
      size: 2
    },
    {
      id:'dip',
      label: 'Dips',
      type: 'unit',
      unit: 'kg',
      size: 2
    },
    {
      id:'pull_up',
      label: 'Pull Ups',
      type: 'unit',
      unit: 'kg',
      size: 2
    },
  ];
  return(
    <StandardForm breadcrumb={false} meta={meta} config={config} onSubmit={handleCreate}/>
  );
}