import { createSlice, nanoid } from '@reduxjs/toolkit';

const workoutsInProgramSlice = createSlice({
  name: 'workoutsInProgram',
  initialState: {
    data: [],
  },
  reducers: {
    addWorkoutToProgram(state, action) {
      state.data.push({
        key: nanoid(),
        id: action.payload.workoutId,
        order: action.payload.order,
      });
    },
    removeWorkoutFromProgram(state, action) {
      let updated = state.data.filter((workout) => {
        return workout.key !== action.payload;
      });
      updated = updated.map((workout, index)=>{
        workout.order = index+1;
        return workout;
      })
      state.data = updated;
    },
    changeProgramOrderUp(state, action) {
      const key = action.payload;

      // Find the index of the workout with the given key
      const workoutIndex = state.data.findIndex((workout) => workout.key === key);

      // Ensure the workout with the given key exists
      if (workoutIndex === -1) {
        console.error('Workout with key not found:', key);
        return;
      }

      // Swap the positions of the workout with the previous workout based on the 'order' field
      if (workoutIndex > 0) {
        state.data[workoutIndex].order = state.data[workoutIndex - 1].order;
        state.data[workoutIndex - 1].order += 1;
      }

      // Sort the data array based on the 'order' field after swapping
      state.data.sort((a, b) => a.order - b.order);
    },
    changeProgramOrderDown(state, action) {
      const key = action.payload;

      // Find the index of the workout with the given key
      const workoutIndex = state.data.findIndex((workout) => workout.key === key);

      // Ensure the workout with the given key exists
      if (workoutIndex === -1) {
        console.error('Workout with key not found:', key);
        return;
      }

      // Swap the positions of the workout with the one after it based on the 'order' field
      if (workoutIndex < state.data.length - 1) {
        state.data[workoutIndex].order = state.data[workoutIndex + 1].order;
        state.data[workoutIndex + 1].order -= 1;
      }

      // Sort the data array based on the 'order' field after swapping
      state.data.sort((a, b) => a.order - b.order);
    },

    cleanProgram(state, action){
      const updated = [];
      state.data = updated;
    },
    initializeProgramWithData(state,action){ //TO DO
      let updated = [];
      action.payload.workout.forEach((workout)=>{
        updated.push({
          key: nanoid(),
          id: workout.id,
          order: workout.order,
        })
      });
      updated.sort((a,b) => a.order - b.order);
      state.data = updated;
    }
  },
});

export const { addWorkoutToProgram, removeWorkoutFromProgram, changeProgramOrderUp, changeProgramOrderDown, cleanProgram, initializeProgramWithData } = workoutsInProgramSlice.actions;
export const workoutsInProgramReducer = workoutsInProgramSlice.reducer;
