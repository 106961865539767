import PRForm from "../../../components/personal_record/PRForm";
import { useParams } from "react-router-dom";
import { useFetchAthleteQuery } from "../../../store";
import PRTable from "../../../components/personal_record/PRTable";
import PRChart from "../../../components/personal_record/PRChart";

export default function PRManager(){
  let table;
  let { id } = useParams();
  const { data, error, isLoading} = useFetchAthleteQuery(id);
  let athlete;
  let content;
  if (isLoading) {
    content = <div> Loading...</div>;
    return content;
  }
  if (error) {
    content = <div> Error: {error.message}</div>;
    return content;
  }
  else{
    athlete = data[0];
    content = <div className="space-y-12">
      <div className="max-w-5xl self-center border-b border-gray-900/10 pb-12">
        <PRChart athlete={athlete} />
      </div>
      <div className="border-b border-gray-900/10 pb-12">
        <PRTable athlete={athlete} />
      </div>
      <div className="pb-12">
        <PRForm athlete={athlete} />
      </div>
    </div>
  }
  return (
    content
  )
}