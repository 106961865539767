import { useFetchWorkoutQuery } from "../../store";
import { ArrowDownIcon, ArrowUpIcon, MinusIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import SetWorkOutInput from "../forms/inputs/SetWorkoutInput";
import { nanoid } from "@reduxjs/toolkit";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function WorkoutInProgramCard ({workout, onRemoveWorkout,  onOrderUpWorkout, onOrderDownWorkout, workoutsLenght}){
  const { data, error, isLoading} = useFetchWorkoutQuery(workout.id);
  
  let renderedExercise;
  if (isLoading){
    renderedExercise = <div> Loading</div>
  } else if (error){
    renderedExercise = <div> Errors</div>
  } else {
    let workoutFromQuery = data[0];
    let orderUpButton = <>
      <button
        type="button"
        onClick={() => {onOrderUpWorkout(workout.key)}}
        className="rounded-full mx-1 bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <ArrowUpIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    </>;
    let orderDownButton = <>
      <button
        type="button"
        onClick={() => {onOrderDownWorkout(workout.key)}}
        className="rounded-full mx-1 bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <ArrowDownIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    </>;
    if(workoutsLenght === 1 || workout.order === 1 ){
      orderUpButton = <></>;
    }
    if(workout.order >= workoutsLenght ){
      orderDownButton = <></>;
    }
    renderedExercise = <>
      <li key={workout.key} className="overflow-hidden rounded-xl border border-gray-200">
        <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
          {/*<img
            src="{exercise.imageUrl}"
            alt={workoutFromQuery.name}
            className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
          />*/}
          <div className="text-sm font-medium leading-6 text-gray-900">{workoutFromQuery.name} {workout.order}</div>
          <div className="relative ml-auto">
            {orderUpButton}
            {orderDownButton}
            <button
              type="button"
              onClick={() => {onRemoveWorkout(workout.key)}}
              className="rounded-full mx-1 bg-red-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <MinusIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
        
      </li>
    </>;
  }
  return(
    <>
    {renderedExercise}
    </>
  );
}

export default WorkoutInProgramCard;