
import { useFetchPlicometryByAtheleteIdQuery } from "../../store";
import TableCard from "../tables/TableCard";
import moment from "moment/moment";
import { calculatePlicometry } from "../../services/plicometry.service";

function PlicoTable({athlete}) {
  const config = [
    {
      key: 'Date',
      label: 'Date',
      render: (plico) => moment(plico.date).format('DD/MM/YYYY'),
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Weight',
      label: 'Weight',
      render: (plico) => plico.weight +' kg',
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Height',
      label: 'Height',
      render: (plico) => plico.height +' cm',
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'LBM',
      label: 'LBM',
      render: (plico) => {
        const calculated = calculatePlicometry(plico,athlete, plico.method);
        return (
            calculated.lbm +' kg'
        );
      },
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Fat',
      label: 'Fat',
      render: (plico) => {
        const calculated = calculatePlicometry(plico,athlete, plico.method);
        return (
            calculated.bodyFat+' %'
        );
      },
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Method',
      label: 'Calculation Method',
      render: (plico) => plico.method,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Chest',
      label: 'Chest',
      render: (plico) => plico.chest +' mm',
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Midaxilla',
      label: 'Midaxilla',
      render: (plico) => plico.midaxilla +' mm',
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Abdomen',
      label: 'Abdomen',
      render: (plico) => plico.abdomen +' mm',
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Suprailiac',
      label: 'Suprailiac',
      render: (plico) => plico.suprailiac +' mm',
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Quadriceps',
      label: 'Quadriceps/Thigh',
      render: (plico) => plico.quadriceps +' mm',
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Subscapular',
      label: 'Subscapular',
      render: (plico) => plico.subscapular +' mm',
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Triceps',
      label: 'Triceps',
      render: (plico) => plico.triceps +' mm',
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    
    {
      key: 'Other',
      label: 'Other',
      render: (plico) => (
        <div>
          {plico.other.map((item, index) => (
            <p key={index}>{item.name} - {item.value +' mm'}</p>
          ))}
        </div>
      ),
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
  ];


  const keyFn = (plico) => {
    return plico.id;
  };

  const { data, error, isLoading} = useFetchPlicometryByAtheleteIdQuery(athlete.id); 
  let table;
  if (isLoading){
    table = <div> Loading</div>
  } else if (error){
    table = <div> Errors</div>
  } else {
    table = <TableCard data={data} config={config} keyFn={keyFn} />;
  }
  return (
    <div className="space-y-12">
      <div className="border-b border-gray-900/10 pb-12">
        {table}
      </div>
    </div>
  );
}

export default PlicoTable;