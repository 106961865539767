import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const authHeaders = { 
  apikey: process.env.REACT_APP_SUPABASE_APIKEY,
  authorization: process.env.REACT_APP_SUPABASE_AUTH_TOKEN,
};
const workoutApi = createApi({
  reducerPath: 'workouts',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://kzsfryzkbjkimvzprysp.supabase.co/rest/v1/'
  }),
  endpoints(builder){
    return {
      fetchWorkouts: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'Workout'}];
        },
        query: (coach) => {
          return {
            url:'/workout',
            params:{
              coach: "eq."+coach,
              order:"id.desc"
              // userId: user.id
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      fetchAvailableWorkouts: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'Workout'}];
        },
        query: (coach) => {
          return {
            url:'/workout',
            params:{
              coach: "eq."+coach,
              program:"is.null"
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      createWorkout: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'Workout'}];
        },
        query: (data) => {
          return {
            url:'/workout',
            body:data,
            method: 'POST',
            headers: {
              ...authHeaders,
              "Prefer": "return=representation",
              "Content-Type": 'application/json'
            },
          }
        }
      }),
      updateWorkout: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'Workout'}];
        },
        query: ({id:workoutId,submitData}) => {
          return {
            url:'/workout',
            params:{
              id:"eq."+workoutId
            },
            body:submitData,
            method: 'PATCH',
            headers: {
              ...authHeaders,
              "Prefer": "return=representation",
              "Content-Type": 'application/json'
            },
          }
        }
      }),
      deleteWorkout: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'Workout'}];
        },
        query: (workoutId) => {
          return {
            url:'/workout',
            params:{
              id:"eq."+workoutId
            },
            
            method: 'DELETE',
            headers: authHeaders
          }
        }
      }),
      fetchWorkout: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'Workout'}];
        },
        query: (workoutId) => {
          return {
            url:'/workout',
            params:{
              id:"eq."+workoutId,
              select: "*,workout_exercise(*,exercise(*),set_objective(*))"
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
    }
  }
});

export const { useFetchWorkoutsQuery, useCreateWorkoutMutation, useUpdateWorkoutMutation, useDeleteWorkoutMutation, useFetchWorkoutQuery, useLazyFetchWorkoutQuery, useFetchAvailableWorkoutsQuery } = workoutApi;
export { workoutApi };