import { createSlice, nanoid } from '@reduxjs/toolkit';

const exercisesInWorkoutSlice = createSlice({
  name: 'exercisesInWorkout',
  initialState: {
    data: [],
  },
  reducers: {
    addExerciseToWorkout(state, action) {
      state.data.push({
        key: nanoid(),
        id: action.payload.exerciseId,
        order: action.payload.order,
        note: null,
        sets:[
          {
            id: nanoid(),
            index: 1,
            kg: null,
            reps: 10,
            rpe: null,
            rir: null,
            tut: null,
            time: null,
            rest: "1'",
            percentage_rm: null,
            note: null
          },{
            id: nanoid(),
            index: 2,
            kg: null,
            reps: 10,
            rpe: null,
            rir: null,
            tut: null,
            time: null,
            rest: "1'",
            percentage_rm: null,
            note: null
          },{
            id: nanoid(),
            index: 3,
            kg: null,
            reps: 10,
            rpe: null,
            rir: null,
            tut: null,
            time: null,
            rest: "1'",
            percentage_rm: null,
            note: null
          }
        ]
      });
    },
    removeExerciseFromWorkout(state, action) {
      let updated = state.data.filter((exercise) => {
        return exercise.key !== action.payload;
      });
      updated = updated.map((exercise, index)=>{
          exercise.order = index+1;
        return exercise;
      })
      state.data = updated;
    },
    changeOrderUp(state, action) {
      let exercise = state.data.filter((exercise) => {
        return exercise.key === action.payload;
      }); //I take the target
      let previousExercise = state.data.filter((prevExercise) => {
        return prevExercise.order === exercise[0].order-1;
      })
      //Update orders
      exercise[0].order = exercise[0].order-1;
      previousExercise[0].order = previousExercise[0].order+1;
      //Delete old objects 
      let updated = state.data.filter((workout) => {
        return workout.key !== exercise[0].key;
      });
      updated = updated.filter((workout) => {
        return workout.key !== previousExercise[0].key;
      });
      //Insert the new ones
      updated = [...updated, exercise[0], previousExercise[0]];
      //Reordering
      updated.sort((a,b) => a.order - b.order);
      //Update state
      state.data = updated;
    },
    changeOrderDown(state, action) {
      let exercise = state.data.filter((workout) => {
        return workout.key === action.payload;
      }); //I take the target
      let nextExercise = state.data.filter((nextExercise) => {
        return nextExercise.order === exercise[0].order+1;
      })
      //Update orders
      exercise[0].order = exercise[0].order+1;
      nextExercise[0].order = nextExercise[0].order-1;
      //Delete old objects 
      let updated = state.data.filter((workout) => {
        return workout.key !== exercise[0].key;
      });
      updated = updated.filter((workout) => {
        return workout.key !== nextExercise[0].key;
      });
      //Insert the new ones
      updated = [...updated, exercise[0], nextExercise[0]];
      //Reordering
      updated.sort((a,b) => a.order - b.order);
      //Update state
      state.data = updated;
    },
    addSet(state, action) {
      let exercise = state.data.filter((workout) => {
        return workout.key === action.payload;
      }); //I take the target
      //add set
      let newSet = {
        id: nanoid(),
        index: exercise[0].sets.length+1,
        kg: null,
        reps: 10,
        rpe: null,
        rir: null,
        tut: null,
        rest: "1'",
        percentage_rm: null,
      }
      exercise[0].sets = [...exercise[0].sets, newSet]
      //Remove the old exercise
      let updated = state.data.filter((workout) => {
        return workout.key !== exercise[0].key;
      });
      //Insert the new exercise with the set updated
      updated = [...updated, exercise[0]];
      //Reordering
      updated.sort((a,b) => a.order - b.order);
      //Update state
      state.data = updated;
    },
    removeSet(state, action){
      const {exerciseKey, setId} = action.payload;
      let exercise = state.data.filter((workout) => {
        return workout.key === exerciseKey;
      }); //I take the exercise target
      //I delete the set from the exercise
      let sets = exercise[0].sets.filter((set) => {
        return set.id !== setId;
      });
      //Updated sets index
      sets = sets.map((set, index)=>{
        set.index = index+1;
        return set;
      })
      //Reorder sets
      sets.sort((a,b) => a.index - b.index);
      //Update sets in exercise.
      exercise[0].sets = sets;
      //Remove the old exercise
      let updated = state.data.filter((exercise) => {
        return exercise.key !== exerciseKey;
      });
      //Insert the new exercise with the sets updated
      updated = [...updated, exercise[0]];
      //Reordering
      updated.sort((a,b) => a.order - b.order);
      //Update state
      state.data = updated;
    },
    editSet(state, action){
      const {exerciseKey, newSet} = action.payload;
      let exercise = state.data.filter((workout) => {
        return workout.key === exerciseKey;
      }); //I take the exercise target
      //I delete the set from the exercise
      let sets = exercise[0].sets.filter((set) => {
        return set.id !== newSet.id;
      });
      //I introduce the new set
      sets = [...sets, newSet];
      //Reordering of sets
      sets.sort((a,b) => a.index - b.index);
      //Update sets in exercise.
      let updatedExercise = {...exercise[0], sets: sets}
      //Remove the old exercise
      let updated = state.data.filter((exercise) => {
        return exercise.key !== exerciseKey;
      });
      //Insert the new exercise with the sets updated
      updated = [...updated, updatedExercise];
      //Reordering
      updated.sort((a,b) => a.order - b.order);
      //Update state
      state.data = updated;
    },
    clean(state, action){
      const updated = [];
      state.data = updated;
    },
    initializewithData(state,action){
      let updated = [];
      action.payload.workout_exercise.forEach((exerciseInWorkout)=>{
        let newSets = [...exerciseInWorkout.set_objective];
        newSets.sort((a,b) => a.index - b.index);
        updated.push({
          key: nanoid(),
          id: exerciseInWorkout.exercise.id,
          order: exerciseInWorkout.order,
          note: exerciseInWorkout.note,
          sets: newSets,
        })
      });
      updated.sort((a,b) => a.order - b.order);
      state.data = updated;
    },
    switchExercise(state, action){
      const {exerciseKey, newExerciseId} = action.payload;
      let targetExercise = state.data.filter((exercise) => {
        return exercise.key === exerciseKey;
      }); //I take the exercise target
      let exercise = targetExercise[0];
      exercise.id = newExerciseId;
      let updated = state.data.filter((exercise) => {
        return exercise.key !== targetExercise[0].key;
      });//I delete the old exercise
      updated = [...updated, exercise];//I insert the new one
      updated.sort((a,b) => a.order - b.order);//reordering
      state.data = updated;
    },
    editExerciseNote(state, action){
      const {exerciseKey, note} = action.payload;
      let targetExercise = state.data.filter((exercise) => {
        return exercise.key === exerciseKey;
      }); //I take the exercise target
      let exercise = targetExercise[0];
      exercise.note = note;
      let updated = state.data.filter((exercise) => {
        return exercise.key !== targetExercise[0].key;
      });//I delete the old exercise
      updated = [...updated, exercise];//I insert the new one
      updated.sort((a,b) => a.order - b.order);//reordering
      state.data = updated;
    }
  },
});

export const { addExerciseToWorkout, removeExerciseFromWorkout, changeOrderUp, changeOrderDown, addSet, 
  removeSet, editSet, clean, initializewithData, switchExercise, editExerciseNote } = exercisesInWorkoutSlice.actions;
export const exercisesInWorkoutReducer = exercisesInWorkoutSlice.reducer;
