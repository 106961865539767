import React, { useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import moment from 'moment';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const CompoundLinearChart = ({ config, data, chartTitle }) => {
  // Find the X variable (date) and Y variables from the config
  const xConfig = config.find(item => item.key.toLowerCase().includes('date'));
  const yConfigs = config.filter(item => !item.key.toLowerCase().includes('date'));

  // Process data to extract X and Y values
  const labels = data.map(item => xConfig.render(item));
  const datasets = yConfigs.map((yConfig, index) => {
    const label = yConfig.label;
    const rawData = data.map(item => yConfig.render(item));
    const parsedData = rawData.map(value => {
      if (typeof value === 'string') {
        const num = parseFloat(value.replace(/[^\d.-]/g, ''));
        return isNaN(num) ? null : num;
      }
      return value;
    }).filter(value => value !== null && value !== undefined);

    return {
      label,
      data: parsedData,
      borderColor: `hsl(${index * 60}, 70%, 50%)`,
      backgroundColor: `hsla(${index * 60}, 70%, 50%, 0.5)`,
      fill: false,
      cubicInterpolationMode: 'monotone'
    };
  });

  const chartData = {
    labels,
    datasets
  };

  const options = {
    
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: chartTitle
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: xConfig.label
        }
      },
      y: {
        title: {
          display: true,
          text: 'Value'
        }
      }
    }
  };

  // Debugging: Inspect the processed data
  // useEffect(() => {
  //   console.log('Labels:', labels);
  //   console.log('Datasets:', datasets);
  // }, [labels, datasets]);

  return <Line data={chartData} options={options} />;
};

export default CompoundLinearChart;
