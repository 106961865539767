import { useState } from "react";

function NumberMealsSlider({elementID, min, max, unit, initialValue, onChange}){
  const [value, setValue] = useState(initialValue);
  const handleChange = (event) =>{
    setValue(event.target.value);
    
    onChange(event.target.value);
  };
  return(
    <div>
      <div className="text-left text-sm">
        {value} {unit}
      </div>
      <input
        type="range"
        className="transparent h-1.5 w-full cursor-pointer appearance-none rounded-lg border-transparent bg-neutral-200"
        id={elementID}
        min={min} max={max}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
}

export default NumberMealsSlider;