import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const authHeaders = { 
  apikey: process.env.REACT_APP_SUPABASE_APIKEY,
  authorization: process.env.REACT_APP_SUPABASE_AUTH_TOKEN,
};
const workoutExerciseApi = createApi({
  reducerPath: 'workoutExercises',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://kzsfryzkbjkimvzprysp.supabase.co/rest/v1/'
  }),
  endpoints(builder){
    return {
      fetchWorkoutsExercises: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'WorkoutExercise'}];
        },
        query: () => {
          return {
            url:'/workout_exercise',
            params:{
              // userId: user.id
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      createWorkoutExercise: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'WorkoutExercise'}];
        },
        query: (data) => {
          return {
            url:'/workout_exercise',
            body:data,
            method: 'POST',
            headers: {
              ...authHeaders,
              "Prefer": "return=representation",
              "Content-Type": 'application/json'
            },
          }
        }
      }),
      updateWorkoutExercise: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'WorkoutExercise'}];
        },
        query: ({id:workoutId,submitData}) => {
          return {
            url:'/workout_exercise',
            params:{
              id:"eq."+workoutId
            },
            body:submitData,
            method: 'PATCH',
            headers: {
              ...authHeaders,
              "Content-Type": 'application/json'
            },
          }
        }
      }),
      deleteWorkoutExercise: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'WorkoutExercise'}];
        },
        query: (workoutId) => {
          return {
            url:'/workout_exercise',
            params:{
              id:"eq."+workoutId
            },
            
            method: 'DELETE',
            headers: authHeaders
          }
        }
      }),
      fetchWorkoutExercise: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'WorkoutExercise'}];
        },
        query: (workoutId) => {
          return {
            url:'/workout_exercise',
            params:{
              id:"eq."+workoutId
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      
    }
  }
});

export const { useFetchWorkoutsExercisesQuery, useCreateWorkoutExerciseMutation, useUpdateWorkoutExerciseMutation, useDeleteWorkoutExerciseMutation, useFetchWorkoutExerciseQuery } = workoutExerciseApi;
export { workoutExerciseApi };