import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const authHeaders = { 
  apikey: process.env.REACT_APP_SUPABASE_APIKEY,
  authorization: process.env.REACT_APP_SUPABASE_AUTH_TOKEN,
};

const dietApi = createApi({
  reducerPath: 'diet',
  tagTypes:['Diet'],
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://kzsfryzkbjkimvzprysp.supabase.co/rest/v1/'
  }),
  endpoints(builder){
    return {
      fetchDiets: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'Diet'}];
        },
        query: () => {
          return {
            url:'/diet',
            params:{
              order:"created_at.asc"
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      createDiet: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'Diet'},{type:'DietNeed'}];
        },
        query: (data) => {
          return {
            url:'/diet',
            body:data,
            method: 'POST',
            headers: {
              ...authHeaders,
              "Content-Type": 'application/json'
            },
          }
        }
      }),
      updateDiet: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'Diet'}];
        },
        query: ({id:dietId,submitData}) => {
          return {
            url:'/diet',
            params:{
              id:"eq."+dietId
            },
            body:submitData,
            method: 'PATCH',
            headers: {
              ...authHeaders,
              "Content-Type": 'application/json'
            },
          }
        }
      }),
      fetchDiet: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'Diet'}];
        },
        query: (dietId) => {
          return {
            url:'/diet',
            params:{
              id:"eq."+dietId
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      fetchDietByAthleteId: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'Diet'}];
        },
        query: (athleteId) => {
          return {
            url:'/diet',
            params:{
              athlete:"eq."+athleteId,
              order:"created_at.desc"
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
    }
  }
});

export const { useFetchDietsQuery, useCreateDietMutation, useUpdateDietMutation, useFetchDietQuery, 
  useFetchDietByAthleteIdQuery, 
  } = dietApi;
export { dietApi };