import SideBar from "../components/navigation/SideBar";
import { useCoach } from "../useCoach";

function Dashboard(){
  const { coach, isLoading:coachLoading } = useCoach();
  console.log(coach);
  return (
    <div>
      <SideBar/>
      <main className="py-10 lg:pl-72">
        
        <div className="px-4 sm:px-6 lg:px-8">
          
          Holaaa
        </div>
      </main>
    </div>
  );
}

export default Dashboard;