import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const authHeaders = { 
  apikey: process.env.REACT_APP_SUPABASE_APIKEY,
  authorization: process.env.REACT_APP_SUPABASE_AUTH_TOKEN,
};
const antropometryApi = createApi({
  reducerPath: 'antropometry',
  tagTypes:['Antropometry'],
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://kzsfryzkbjkimvzprysp.supabase.co/rest/v1/'
  }),
  endpoints(builder){
    return {
      fetchAntropometrys: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'Antropometry'}];
        },
        query: () => {
          return {
            url:'/antropometry',
            params:{
              order:"created_at.asc"
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      createAntropometry: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'Antropometry'}];
        },
        query: (data) => {
          return {
            url:'/antropometry',
            body:data,
            method: 'POST',
            headers: {
              ...authHeaders,
              "Content-Type": 'application/json'
            },
          }
        }
      }),
      updateAntropometry: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'Antropometry'}];
        },
        query: ({id:antropometryId,submitData}) => {
          return {
            url:'/antropometry',
            params:{
              id:"eq."+antropometryId
            },
            body:submitData,
            method: 'PATCH',
            headers: {
              ...authHeaders,
              "Content-Type": 'application/json'
            },
          }
        }
      }),
      fetchAntropometry: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'Antropometry'}];
        },
        query: (antropometryId) => {
          return {
            url:'/antropometry',
            params:{
              id:"eq."+antropometryId
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      fetchAntropometryByAthleteId: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'Antropometry'}];
        },
        query: (athleteId) => {
          return {
            url:'/antropometry',
            params:{
              athlete:"eq."+athleteId,
              order:"created_at.desc"
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
    }
  }
});

export const { useFetchAntropometrysQuery, useCreateAntropometryMutation, useUpdateAntropometryMutation, useFetchAntropometryQuery, 
  useFetchAntropometryByAthleteIdQuery, 
  } = antropometryApi;
export { antropometryApi };