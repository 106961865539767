import { useDispatch } from "react-redux";
import { editSet } from "../../../store";


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function SetWorkOutInput ({exerciseKey, set, value, attribute}){
  const dispatch = useDispatch();
  
  const handleChange = (e)=>{
    let value = e.target.value;
    let newSet;
    switch (attribute){
      case 'kg':
        newSet = {...set,kg:value};
        dispatch(editSet({exerciseKey, newSet}));
        break;
      case 'reps':
        newSet = {...set,reps:value};
        dispatch(editSet({exerciseKey, newSet}));
        break;
      case 'rpe':
        newSet = {...set,rpe:value};
        dispatch(editSet({exerciseKey, newSet}));
        break;
      case 'rir':
        newSet = {...set,rir:value};
        dispatch(editSet({exerciseKey, newSet}));
        break;
      case 'tut':
        newSet = {...set,tut:value};
        dispatch(editSet({exerciseKey, newSet}));
        break;
      case 'percentage_rm':
        newSet = {...set,percentage_rm:value};
        dispatch(editSet({exerciseKey, newSet}));
        break;
      case 'time':
        newSet = {...set,time:value};
        dispatch(editSet({exerciseKey, newSet}));
        break;
      case 'rest':
        newSet = {...set,rest:value};
        dispatch(editSet({exerciseKey, newSet}));
        break;
      default:
        console.log("Error in attribute");
    }
  };
  return (
  <>
    <input key={exerciseKey+"-"+set.id+"-"+attribute} id={exerciseKey+"-"+set.id+"-"+attribute}  onBlur={handleChange} type="text" defaultValue={value} className={classNames(
        attribute === "tut"
        ? 'w-14'
        : 'w-12',
      'sm:text-xs'
      )}
    />
  </>
  );
}

export default SetWorkOutInput;