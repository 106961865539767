import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import SearchProgram from '../workout/SearchProgram';
import { useParams } from 'react-router-dom';
import { useCreateProgramAthleteMutation } from '../../store/apis/programAthleteApi';


export default function AssignProgramModal({ open, setOpen }) {
  const cancelButtonRef = useRef(null);
  let { id:athleteId } = useParams();
  const [assignProgram] = useCreateProgramAthleteMutation();

  const handleAssign = (programId)=>{
    let submitData = {athlete: athleteId, program:programId};
    assignProgram(submitData);
    setOpen(false);
  }
  
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed top-0 left-0 w-full h-full z-50 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="flex min-h-screen items-center justify-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="relative transform overflow-hidden w-full md:w-3/4 lg:w-1/2 bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6 rounded-lg">
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                <MagnifyingGlassIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                  Assign training
                </Dialog.Title>
                <div className="mt-2">
                  <SearchProgram onAdd={handleAssign}/>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}