import { createSlice } from "@reduxjs/toolkit";

const mealPlanFormSlice = createSlice({
  name: "mealPlanForm",
  initialState: {
    numberMeals: 3,
    numberSnacks: 0,
    preworkout: false,
    postworkout: false,
    restriction: { id: 1, title: 'Anything', description: ''},
    mealLanguage: { id: 1, value:'English', label: 'English'},
    allergies: [],
    includedFoods: ['Rice', 'Wholegrain rice', 'Brown rice', 'Pasta', 'Wholegrain pasta',],
    excludedFoods: ['Cauliflower',  ],
    comments:""
  },
  reducers: {
    changeNumberMeals(state, action) {
      state.numberMeals = action.payload;
    },
    changeNumberSnacks(state, action) {
      state.numberSnacks= action.payload;
    },
    changePreworkout(state, action) {
      state.preworkout = action.payload;
    },
    changePostworkout(state, action) {
      state.postworkout = action.payload;
    },
    changeRestriction(state, action) {
      state.restriction = action.payload;
    },
    changeMealLanguage(state, action) {
      state.mealLanguage = action.payload;
    },
    changeAllergies(state, action) {
      state.allergies = action.payload;
    },
    changeIncludedFoods(state, action) {
      state.includedFoods = action.payload;
    },
    changeExcludedFoods(state, action) {
      state.excludedFoods = action.payload;
    },
    changeComments(state, action) {
      state.comments = action.payload;
    },
  }
});

export const { changeNumberMeals, changeNumberSnacks, changePreworkout, changePostworkout, changeRestriction,
  changeMealLanguage, changeAllergies, changeIncludedFoods, changeExcludedFoods, changeComments
} = mealPlanFormSlice.actions;
export const mealPlanFormReducer = mealPlanFormSlice.reducer;
