import { useState, useEffect } from 'react';

export default function TagList({ initialTags = [], onTagsChange, label }) {
  const [tags, setTags] = useState(initialTags);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    onTagsChange(tags);
  }, [tags, onTagsChange]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevenir el submit del formulario
      if (inputValue.trim() !== '') {
        setTags([...tags, inputValue.trim()]);
        setInputValue('');
      }
    }
  };

  const removeTag = (indexToRemove) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
  };

  return (
    <div className="w-full max-w-md mx-auto mt-4">
      <label htmlFor="tag-input" className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <div className="mt-2 flex flex-wrap items-center border rounded-md p-2">
        {tags.map((tag, index) => (
          <div
            key={index}
            className="flex items-center bg-black text-white text-sm rounded-full px-3 py-1 mr-2 mb-2"
          >
            {tag}
            <button
              type="button"
              className="ml-2 text-white focus:outline-none"
              onClick={() => removeTag(index)}
            >
              ×
            </button>
          </div>
        ))}
        <input
          id="tag-input"
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Press Enter to add tags"
          className="flex-grow border-0 py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
        />
      </div>
    </div>
  );
}
