import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const authHeaders = { 
  apikey: process.env.REACT_APP_SUPABASE_APIKEY,
  authorization: process.env.REACT_APP_SUPABASE_AUTH_TOKEN,
};
const prApi = createApi({
  reducerPath: 'personal_records',
  tagTypes:['PersonalRecord'],
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://kzsfryzkbjkimvzprysp.supabase.co/rest/v1/'
  }),
  endpoints(builder){
    return {
      fetchRecords: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'PersonalRecord'}];
        },
        query: () => {
          return {
            url:'/personal_record',
            params:{
              order:"created_at.asc"
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      createRecord: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'PersonalRecord'}];
        },
        query: (data) => {
          return {
            url:'/personal_record',
            body:data,
            method: 'POST',
            headers: {
              ...authHeaders,
              "Content-Type": 'application/json'
            },
          }
        }
      }),
      updateRecord: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'PersonalRecord'}];
        },
        query: ({id:personal_recordId,submitData}) => {
          return {
            url:'/personal_record',
            params:{
              id:"eq."+personal_recordId
            },
            body:submitData,
            method: 'PATCH',
            headers: {
              ...authHeaders,
              "Content-Type": 'application/json'
            },
          }
        }
      }),
      fetchRecord: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'PersonalRecord'}];
        },
        query: (personal_recordId) => {
          return {
            url:'/personal_record',
            params:{
              id:"eq."+personal_recordId
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      fetchRecordByAthleteId: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'PersonalRecord'}];
        },
        query: (athleteId) => {
          return {
            url:'/personal_record',
            params:{
              athlete:"eq."+athleteId,
              order:"created_at.desc"
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
    }
  }
});

export const { useFetchRecordsQuery, useCreateRecordMutation, useUpdateRecordMutation, useFetchRecordQuery, 
  useFetchRecordByAthleteIdQuery, 
  } = prApi;
export { prApi };