import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const authHeaders = { 
  apikey: process.env.REACT_APP_SUPABASE_APIKEY,
  authorization: process.env.REACT_APP_SUPABASE_AUTH_TOKEN,
};
const dietNeedsApi = createApi({
  reducerPath: 'dietNeeds',
  tagTypes:['DietNeed'],
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://kzsfryzkbjkimvzprysp.supabase.co/rest/v1/'
  }),
  endpoints(builder){
    return {
      fetchDietNeeds: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'DietNeed'}];
        },
        query: () => {
          return {
            url:'/diet_needs',
            params:{
              order:"created_at.asc"
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      createDietNeed: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'DietNeed'}];
        },
        query: (data) => {
          return {
            url:'/diet_needs',
            body:data,
            method: 'POST',
            headers: {
              ...authHeaders,
              "Content-Type": 'application/json'
            },
          }
        }
      }),
      updateDietNeed: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'DietNeed'}];
        },
        query: ({id:dietNeedId,submitData}) => {
          return {
            url:'/diet_needs',
            params:{
              id:"eq."+dietNeedId
            },
            body:submitData,
            method: 'PATCH',
            headers: {
              ...authHeaders,
              "Content-Type": 'application/json'
            },
          }
        }
      }),
      fetchDietNeed: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'DietNeed'}];
        },
        query: (dietNeedId) => {
          return {
            url:'/diet_needs',
            params:{
              id:"eq."+dietNeedId
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      fetchDietNeedByUserId: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'DietNeed'}];
        },
        query: (userId) => {
          return {
            url:'/diet_needs',
            params:{
              athlete:"eq."+userId,
              select:"*,diet(*)"
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      
    }
  }
});

export const { useFetchDietNeedsQuery, useCreateDietNeedMutation, useUpdateDietNeedMutation, useFetchDietNeedQuery, 
  useFetchDietNeedByUserIdQuery, 
   } = dietNeedsApi;
export { dietNeedsApi };