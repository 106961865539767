import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";
import { NavLink, useNavigate } from "react-router-dom";

function BreadCrumb({meta}){
  const navigate = useNavigate();
  
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        <li>
          <div>
            <NavLink onClick={(e)=>{
                  e.preventDefault();
                  navigate(-1)
            }} className="text-gray-400 hover:text-gray-500">
              <ArrowUturnLeftIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
            </NavLink>
          </div>
        </li>
        <li >
            <div className="flex items-center">
              <svg
                className="h-5 w-5 flex-shrink-0 text-gray-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              <NavLink
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                {meta.action}
              </NavLink>
            </div>
          </li>
      </ol>
    </nav>
  );
}

export default BreadCrumb;