
import ExerciseInWorkoutCard from './ExerciseInWorkoutCard'

export default function ExercisesInWorkoutList({exercises, onClickRemove, onClickAddSet, onClickRemoveSet, onClickOrderUp, onClickOrderDown}) {
  
  return (
    <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-1 xl:gap-x-8">
      {exercises.map((exercise) => (
        <ExerciseInWorkoutCard key={exercise.key} exercise={exercise} exercisesLenght={exercises.length} onRemoveExercise={onClickRemove} 
        onAddSet={onClickAddSet} onRemoveSet={onClickRemoveSet} onOrderUpExercise={onClickOrderUp} onOrderDownExercise={onClickOrderDown} />
      ))}
      
    </ul>
  )
}
