import React from "react";

export const PageLoader = () => {
  return (
    <div className="flex flex-col items-center animate-pulse justify-center h-screen bg-white">
      <img
        src="/images/logoHeadBlack.png"
        alt="Loading..."
        className="w-64 h-64"
      />
      <p className="mt-0 text-gray-600">Loading</p>
    </div>
  );
};
