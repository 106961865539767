import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const authHeaders = { 
  apikey: process.env.REACT_APP_SUPABASE_APIKEY,
  authorization: process.env.REACT_APP_SUPABASE_AUTH_TOKEN,
};
const fmsApi = createApi({
  reducerPath: 'fmss',
  tagTypes:['Fms'],
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://kzsfryzkbjkimvzprysp.supabase.co/rest/v1/'
  }),
  endpoints(builder){
    return {
      fetchFmss: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'Fms'}];
        },
        query: () => {
          return {
            url:'/fms',
            params:{
              order:"created_at.asc"
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      createFms: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'Fms'}];
        },
        query: (data) => {
          return {
            url:'/fms',
            body:data,
            method: 'POST',
            headers: {
              ...authHeaders,
              "Content-Type": 'application/json'
            },
          }
        }
      }),
      updateFms: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'Fms'}];
        },
        query: ({id:fmsId,submitData}) => {
          return {
            url:'/fms',
            params:{
              id:"eq."+fmsId
            },
            body:submitData,
            method: 'PATCH',
            headers: {
              ...authHeaders,
              "Content-Type": 'application/json'
            },
          }
        }
      }),
      fetchFms: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'Fms'}];
        },
        query: (fmsId) => {
          return {
            url:'/fms',
            params:{
              id:"eq."+fmsId
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      fetchFmsByAthleteId: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'Fms'}];
        },
        query: (athleteId) => {
          return {
            url:'/fms',
            params:{
              athlete:"eq."+athleteId,
              order:"created_at.desc"
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
    }
  }
});

export const { useFetchFmssQuery, useCreateFmsMutation, useUpdateFmsMutation, useFetchFmsQuery, 
  useFetchFmsByAthleteIdQuery, 
  } = fmsApi;
export { fmsApi };