import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const authHeaders = { 
  apikey: process.env.REACT_APP_SUPABASE_APIKEY,
  authorization: process.env.REACT_APP_SUPABASE_AUTH_TOKEN,
};
const athleteApi = createApi({
  reducerPath: 'athletes',
  tagTypes:['Athlete'],
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://kzsfryzkbjkimvzprysp.supabase.co/rest/v1/'
  }),
  endpoints(builder){
    return {
      fetchAthletes: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'Athlete'}];
        },
        query: (coach) => {
          return {
            url:'/athlete',
            params:{
              coach:"eq."+coach,
              order:"created_at.asc"
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      createAthlete: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'Athlete'}];
        },
        query: (data) => {
          return {
            url:'/athlete',
            body:data,
            method: 'POST',
            headers: {
              ...authHeaders,
              "Content-Type": 'application/json'
            },
          }
        }
      }),
      updateAthlete: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'Athlete'}];
        },
        query: ({id:athleteId,submitData}) => {
          return {
            url:'/athlete',
            params:{
              id:"eq."+athleteId
            },
            body:submitData,
            method: 'PATCH',
            headers: {
              ...authHeaders,
              "Content-Type": 'application/json'
            },
          }
        }
      }),
      fetchAthlete: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'Athlete'}];
        },
        query: (athleteId) => {
          return {
            url:'/athlete',
            params:{
              id:"eq."+athleteId
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      fetchAthleteByNativeUserId: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'Athlete'}];
        },
        query: (userId) => {
          return {
            url:'/athlete',
            params:{
              native_user:"eq."+userId
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      fetchAthleteJournalByNativeUserId: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'Athlete'}];
        },
        query: (userId) => {
          return {
            url:'/athlete',
            params:{
              native_user:"eq."+userId,
              select:"*,journal(*)"
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
    }
  }
});

export const { useFetchAthletesQuery, useCreateAthleteMutation, useUpdateAthleteMutation, useFetchAthleteQuery, 
  useFetchAthleteByNativeUserIdQuery, useLazyFetchAthleteByNativeUserIdQuery, 
  useFetchAthleteJournalByNativeUserIdQuery, useFetchCleanAthleteQuery, useLazyFetchCleanAthleteQuery } = athleteApi;
export { athleteApi };