import SideBar from "../../components/navigation/SideBar";
import BasicActions from "../../components/headings/BasicActions";
import TableCard from "../../components/tables/TableCard";
import { NavLink, useNavigate } from "react-router-dom";
import { useDeleteExerciseMutation, useFetchExercisesQuery } from "../../store";
import { useCoach } from "../../useCoach";

function Exercises(){
  const { coach } = useCoach();
  const navigate = useNavigate();
  const [deleteExercise, results] = useDeleteExerciseMutation();
  const handleDelete = (e,exerciseId)=>{
    e.preventDefault();
    deleteExercise(exerciseId);
  }
  const config = [
    {
      key: 'Id',
      label: 'Id',
      render: (exercise) => exercise.id,
      rowClassname: 'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6',
      columnClassname: 'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6',
    },
    {
      key: 'Name',
      label: 'Name',
      render: (exercise) => exercise.name,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Edit',
      label: '',
      render: (exercise) => <NavLink to={`/exercise/${exercise.id}`} className="text-indigo-600 hover:text-indigo-900">
        Edit<span className="sr-only">, {exercise.name}</span>
        </NavLink>,
      rowClassname: 'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Delete',
      label: '',
      render: (exercise) => <NavLink to="/" onClick={(e)=>handleDelete(e,exercise.id)} className="text-indigo-600 hover:text-indigo-900">
        Delete<span className="sr-only">{exercise.name}</span>
        </NavLink>,
      rowClassname: 'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
  ];

  const handleNew = (event)=>{
    event.preventDefault();
    navigate("/exercise/create");
  }

  const actions = [
    {label: 'New', onClick:handleNew, class: 'primary'},
  ]

  const keyFn = (exercise) => {
    return exercise.id;
  };

  const { data, error, isLoading} = useFetchExercisesQuery(coach.id);
  let table;
  if (isLoading){
    table = <div> Loading</div>
  } else if (error){
    table = <div> Errors</div>
  } else {
    table = <TableCard data={data} config={config} keyFn={keyFn} />;
  }
  return (
    <>
    <div>
      <SideBar/>
      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">
          <BasicActions object="Exercises" actions={actions}/>
          {table}
        </div>
      </main>
    </div>
    </>
  );
}

export default Exercises;