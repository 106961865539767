import WorkoutInProgramCard from "./WorkoutInProgramCard";

export default function WorkoutsInProgramList({workouts, onClickRemove, onClickOrderUp, onClickOrderDown}) {
  
  return (
    <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-1 xl:gap-x-8">
      {workouts.map((workout) => (
        <WorkoutInProgramCard key={workout.key} workout={workout} workoutsLenght={workouts.length} onRemoveWorkout={onClickRemove} 
         onOrderUpWorkout={onClickOrderUp} onOrderDownWorkout={onClickOrderDown} />
      ))}
      
    </ul>
  )
}
