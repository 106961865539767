import React from 'react';
import { useFetchAllProgramDetailsByAthleteQuery } from '../../../store';
import TrainingTableList from '../../../components/tables/TrainingTableList';

function ProgramProgress({ program }) {
  const { data, error, isLoading } = useFetchAllProgramDetailsByAthleteQuery({athleteId: program.athlete, programId: program.program.id});
  let content;

  if (isLoading) {
    content = <div>Loading</div>;
  } else if (error) {
    content = <div>{JSON.stringify(error, null, 2)}</div>;
  } else {
    const dataFiltered = data[0].program_details.workout;
    const programWorkouts = [...dataFiltered].sort((a, b) => a.order - b.order);
    
    content = <TrainingTableList programWorkouts={programWorkouts} programName={program.program.name} />
  }

  return (
    <div className="mt-8 bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 text-gray-900">
          Program Progress for {program.program.name}
        </h3>
        <div className="mt-5">
          {content}
        </div>
      </div>
    </div>
  );
}

export default ProgramProgress;
