import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const authHeaders = { 
  apikey: process.env.REACT_APP_SUPABASE_APIKEY,
  authorization: process.env.REACT_APP_SUPABASE_AUTH_TOKEN,
};
const setObjectiveApi = createApi({
  reducerPath: 'setObjectives',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://kzsfryzkbjkimvzprysp.supabase.co/rest/v1/'
  }),
  endpoints(builder){
    return {
      fetchSetObjectives: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'SetObjective'}];
        },
        query: () => {
          return {
            url:'/set_objective',
            params:{
              // userId: user.id
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      createSetObjective: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'SetObjective'}];
        },
        query: (data) => {
          return {
            url:'/set_objective',
            body:data,
            method: 'POST',
            headers: {
              ...authHeaders,
              "Prefer": "return=representation",
              "Content-Type": 'application/json'
            },
          }
        }
      }),
      updateSetObjective: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'SetObjective'}];
        },
        query: ({id:workoutId,submitData}) => {
          return {
            url:'/set_objective',
            params:{
              id:"eq."+workoutId
            },
            body:submitData,
            method: 'PATCH',
            headers: {
              ...authHeaders,
              "Content-Type": 'application/json'
            },
          }
        }
      }),
      deleteSetObjective: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'SetObjective'}];
        },
        query: (workoutId) => {
          return {
            url:'/set_objective',
            params:{
              id:"eq."+workoutId
            },
            
            method: 'DELETE',
            headers: authHeaders
          }
        }
      }),
      fetchSetObjective: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'SetObjective'}];
        },
        query: (workoutId) => {
          return {
            url:'/set_objective',
            params:{
              id:"eq."+workoutId
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      
    }
  }
});

export const { useFetchSetObjectivesQuery, useCreateSetObjectiveMutation, useUpdateSetObjectiveMutation, useDeleteSetObjectiveMutation, useFetchSetObjectiveQuery } = setObjectiveApi;
export { setObjectiveApi };