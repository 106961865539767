import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const authHeaders = { 
  apikey: process.env.REACT_APP_SUPABASE_APIKEY,
  authorization: process.env.REACT_APP_SUPABASE_AUTH_TOKEN,
};
const coachApi = createApi({
  reducerPath: 'coaches',
  tagTypes:['Coach'],
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://kzsfryzkbjkimvzprysp.supabase.co/rest/v1/'
  }),
  endpoints(builder){
    return {
      fetchCoachs: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'Coach'}];
        },
        query: () => {
          return {
            url:'/coach',
            params:{
              order:"created_at.asc"
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      createCoach: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'Coach'}];
        },
        query: (data) => {
          return {
            url:'/coach',
            body:data,
            method: 'POST',
            headers: {
              ...authHeaders,
              "Content-Type": 'application/json'
            },
          }
        }
      }),
      updateCoach: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'Coach'}];
        },
        query: ({id:coachId,submitData}) => {
          return {
            url:'/coach',
            params:{
              id:"eq."+coachId
            },
            body:submitData,
            method: 'PATCH',
            headers: {
              ...authHeaders,
              "Content-Type": 'application/json'
            },
          }
        }
      }),
      fetchCoach: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'Coach'}];
        },
        query: (coachId) => {
          return {
            url:'/coach',
            params:{
              id:"eq."+coachId
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      fetchCoachByAuthId: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'Coach'}];
        },
        query: (authId) => {
          return {
            url:'/coach',
            params:{
              authId:"eq."+authId
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      
    }
  }
});

export const { useFetchCoachsQuery, useCreateCoachMutation, useUpdateCoachMutation, useFetchCoachQuery, 
  useFetchCoachByAuthIdQuery, useFetchCleanCoachQuery, useLazyFetchCleanCoachQuery } = coachApi;
export { coachApi };