import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import TableCard from "../../components/tables/TableCard";
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';

const WorkoutsTable = ({ data, handleDuplicate, handleDelete, refetch }) => {
  const [hideAssigned, setHideAssigned] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const toggleHideAssigned = () => {
    setHideAssigned(!hideAssigned);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  let filteredData = data;
  if (hideAssigned && data) {
    filteredData = data.filter(workout => workout.program === null);
  }

  if (searchTerm && data) {
    filteredData = filteredData.filter(workout => 
      workout.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  const config = [
    {
      key: 'Id',
      label: 'Id',
      render: (workout) => workout.id,
      rowClassname: 'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6',
      columnClassname: 'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6',
    },
    {
      key: 'Name',
      label: 'Name',
      render: (workout) => workout.name,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Duplicate',
      label: '',
      render: (workout) => <button
        onClick={() => handleDuplicate(workout.id, refetch)}
        className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
      > Duplicate
      </button>,
      rowClassname: 'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Edit',
      label: '',
      render: (workout) => <NavLink to={`/workout/${workout.id}`} className="text-indigo-600 hover:text-indigo-900">
        Edit<span className="sr-only">, {workout.name}</span>
      </NavLink>,
      rowClassname: 'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Delete',
      label: '',
      render: (workout) => <NavLink to="/" onClick={(e) => handleDelete(e, workout.id)} className="text-indigo-600 hover:text-indigo-900">
        Delete<span className="sr-only">{workout.name}</span>
      </NavLink>,
      rowClassname: 'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
  ];

  const keyFn = (workout) => {
    return workout.id;
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flex items-center ml-8 mb-4 mt-8">
        <button
          onClick={toggleHideAssigned}
          className="mr-4 rounded-md bg-blue-500 px-4 py-2 text-white"
        >
          {hideAssigned ? 'Show all workouts' : 'Hide workouts assigned to a program'}
        </button>
        <div className="relative">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Search workouts"
            className="rounded-md border-gray-300 px-4 py-2 pl-10"
          />
          <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
        </div>
      </div>
      <TableCard data={filteredData} config={config} keyFn={keyFn} />
    </div>
  );
};

export default WorkoutsTable;
