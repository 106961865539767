import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const authHeaders = { 
  apikey: process.env.REACT_APP_SUPABASE_APIKEY,
  authorization: process.env.REACT_APP_SUPABASE_AUTH_TOKEN,
};
const journalApi = createApi({
  reducerPath: 'journals',
  tagTypes: ['Journal'],
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://kzsfryzkbjkimvzprysp.supabase.co/rest/v1/'
  }),
  endpoints(builder){
    return {
      fetchJournals: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'Journal'}];
        },
        query: () => {
          return {
            url:'/journal',
            params:{
              // userId: user.id
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      createJournal: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'Journal'}];
        },
        query: (data) => {
          return {
            url:'/journal',
            body:data,
            method: 'POST',
            headers: {
              ...authHeaders,
              "Content-Type": 'application/json'
            },
          }
        }
      }),
      updateJournal: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'Journal'}];
        },
        query: ({id:journalId,submitData}) => {
          return {
            url:'/journal',
            params:{
              id:"eq."+journalId
            },
            body:submitData,
            method: 'PATCH',
            headers: {
              ...authHeaders,
              "Content-Type": 'application/json'
            },
          }
        }
      }),
      fetchJournal: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'Journal'}];
        },
        query: (journalId) => {
          return {
            url:'/journal',
            params:{
              id:"eq."+journalId
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      fetchJournalByAthlete: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'Journal'}];
        },
        query: (athleteId) => {
          return {
            url:'/journal',
            params:{
              athlete:"eq."+athleteId,
              order:"date.desc"
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      fetchJournalByAthleteAndDate: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'Journal'}];
        },
        query: ({athleteId,date}) => {
          return {
            url:'/journal',
            params:{
              athlete:"eq."+athleteId,
              date:"eq."+date
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      fetchJournalUserId: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'Journal'}];
        },
        query: (userId) => {
          return {
            url:'/journal',
            params:{
              order:"date.desc",
              select:"*,athlete_id:athlete,athlete(*)",
              "athlete.native_user":"eq."+userId,
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
    }
  }
});

export const { useFetchJournalsQuery, useCreateJournalMutation, useUpdateJournalMutation, useFetchJournalQuery, 
  useFetchJournalByAthleteQuery, useFetchJournalByAthleteAndDateQuery, useFetchJournalUserIdQuery,
  useLazyFetchJournalByAthleteAndDateQuery } = journalApi;
export { journalApi };