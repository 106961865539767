import SideBar from "../../components/navigation/SideBar";
import { useNavigate } from "react-router-dom";
import SearchWorkout from "../../components/workout/SearchWorkout";
import WorkoutsInProgramList from "../../components/workout/WorkoutsInProgramList";
import { useDispatch, useSelector } from "react-redux";
import StandardForm from "../../components/forms/StandardForm";
import { addWorkoutToProgram, changeProgramOrderDown, changeProgramOrderUp, cleanProgram, removeWorkoutFromProgram, useCreateProgramMutation, useUpdateProgramMutation, useUpdateWorkoutMutation } from "../../store";
import { useEffect } from "react";
import { useCoach } from "../../useCoach";

function CreateProgram(){
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { coach } = useCoach();
  const [createProgram] = useCreateProgramMutation();
  const [updateWorkout] = useUpdateWorkoutMutation();
  const meta = {
    object: "Program",
    action: "Create new Program",
    description: "Create a new macrocyle",
  };
  const config = [
    {
      id:'name',
      label: 'Program name',
      type: 'shortText',
      placeholder: 'Macrocycle 1',
      size: 2
    }
  ];
  const { workoutsInProgram } = useSelector((state) => {
    return {workoutsInProgram: state.workoutsInProgram.data};
  });
  const handleCreate = async (e)=> {
    e.preventDefault();
    const submitData = config.reduce((o, input) => ({
       ...o, 
       [input.id]: e.target[input.id].value,
       coach: coach.id
    }), {});
    const {data} = await createProgram(submitData); 
    const createdProgram = data[0];
    workoutsInProgram.forEach(async(workout)=>{
      let updatedData = {program:createdProgram.id, order:workout.order};
        await updateWorkout({id:workout.id, submitData:updatedData});
      });
    dispatch(cleanProgram());
    navigate("/programs");
  }
  const handleAddWorkout = (workoutId)=>{ 
    dispatch(addWorkoutToProgram({workoutId:workoutId, order:workoutsInProgram.length+1}));
  };
  const handleExerciseRemove = (key)=>{
    dispatch(removeWorkoutFromProgram(key));
  }
  const handleChangeOrderUp = (key)=>{
    dispatch(changeProgramOrderUp(key)); 
  }
  const handleChangeOrderDown = (key)=>{
    dispatch(changeProgramOrderDown(key));
  }
  
  useEffect(()=>{
    dispatch(cleanProgram());
  }, [dispatch]);
  return(
    <>
    <div>
      <SideBar/>
      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">
          <StandardForm meta={meta} config={config} colsForm={1} onSubmit={handleCreate}/>
        </div>
        <div className="mx-auto max-w-12xl px-4 py-2 sm:px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              <div className="lg:col-start-3 lg:row-end-1">
                <SearchWorkout onAddWorkout={handleAddWorkout}/>
              </div>

              {/*Left */}
              <div className="-mx-4 px-4 py-8 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-2 xl:pb-10 xl:pt-8">
                <h2 className="text-base pb-6 font-semibold leading-6 text-gray-900">Workouts in program</h2>
                
                <WorkoutsInProgramList workouts={workoutsInProgram} onClickRemove={handleExerciseRemove} onClickOrderUp={handleChangeOrderUp} onClickOrderDown={handleChangeOrderDown}/>
              </div>
            </div>
          </div>
      </main>
    </div>
    </>
  );
}

export default CreateProgram;