import moment from "moment/moment";

export const calculatePlicometry =  (foldsData, athlete, method) => {
  const methodId = method === "Jackson & Pollock 7 skin folds" ? 7 : (method === "Jackson & Pollock 4 skin folds" ? 4 : 3);
  const age = moment().diff(athlete.birthdate, 'years');
  let bodyDensity;
  let bodyFat;
  const folds = {
    chest: convertToNumber(foldsData.chest),
    midaxilla: convertToNumber(foldsData.midaxilla),
    abdomen: convertToNumber(foldsData.abdomen),
    suprailiac: convertToNumber(foldsData.suprailiac),
    quadriceps: convertToNumber(foldsData.quadriceps),
    subscapular: convertToNumber(foldsData.subscapular),
    triceps: convertToNumber(foldsData.triceps)
  };
  if(athlete.sex === "Male"){
    bodyDensity = malebodydensity(methodId, age, folds.chest, folds.midaxilla, folds.abdomen, folds.suprailiac, folds.quadriceps, folds.subscapular, folds.triceps);
    if(methodId === 4){
      bodyFat = bodyDensity;
    }else{
      bodyFat = bodyfat(bodyDensity);
    }
  }else{
    bodyDensity = femalebodydensity(methodId, age, folds.chest, folds.midaxilla, folds.abdomen, folds.suprailiac, folds.quadriceps, folds.subscapular, folds.triceps);
      if(methodId === 4){
        bodyFat = bodyDensity;
      }else{
        bodyFat = bodyfat(bodyDensity);
      }
  }
  let lbm = leanBodyMass(foldsData.weight, bodyFat);
  return {
    lbm: lbm,
    bodyFat: bodyFat
  };
};


function malebodydensity(formula, age, chest, midaxilla, abdomen, suprailiac, quadriceps, subscapular, triceps){
  if(formula === 7){
      let sumFolds = chest+midaxilla+abdomen+suprailiac+quadriceps+subscapular+triceps;
      let sumSquareFolds = sumFolds*sumFolds;
      let bodydensity = 1.112-(0.00043499*sumFolds)+(0.00000055*sumSquareFolds)-(0.00028826*age);
      return bodydensity;
  }else if(formula === 4){
      let sumFolds = abdomen+suprailiac+quadriceps+triceps;
      let sumSquareFolds = sumFolds*sumFolds;
      let bodydensity = (0.29288*sumFolds)-(0.0005*sumSquareFolds)+(0.15845*age)-5.76377;
      return bodydensity;
  }else if(formula === 3){
      let sumFolds = chest+abdomen+quadriceps;
      let sumSquareFolds = sumFolds*sumFolds;
      let bodydensity = 1.10938-(0.0008267*sumFolds)+(0.0000016*sumSquareFolds)-(0.0002574*age);
      return bodydensity;
  }
  
}

function femalebodydensity(formula, age, chest, midaxilla, abdomen, suprailiac, quadriceps, subscapular, triceps){
  if(formula === 7){
      let sumFolds = chest+midaxilla+abdomen+suprailiac+quadriceps+subscapular+triceps;
      let sumSquareFolds = sumFolds*sumFolds;
      let bodydensity = 1.097-(0.00046971*sumFolds)+(0.00000056*sumSquareFolds)-(0.00012828*age);
      return bodydensity;
  }else if(formula === 4){
      let sumFolds = abdomen+suprailiac+quadriceps+triceps;
      let sumSquareFolds = sumFolds*sumFolds;
      let bodydensity = (0.29669*sumFolds)-(0.00043*sumSquareFolds)+(0.02963*age)+1.4072;
      return bodydensity;
  }else if(formula === 3){
      let sumFolds = chest+abdomen+quadriceps;
      let sumSquareFolds = sumFolds*sumFolds;
      let bodydensity = 1.0994921-(0.0009929*sumFolds)+(0.0000023*sumSquareFolds)-(0.0001392*age);
      return bodydensity;
  }
}

function bodyfat(bodydensity){
  let bodyFat = (495/bodydensity)-450;
  return bodyFat;
}

function leanBodyMass(weight, bodyfat){
  weight = convertToNumber(weight);
  let lbm = weight - (weight*bodyfat)/100;
  return lbm;
}

function convertToNumber(value) {
  // Verificar si el valor es de tipo string
  if (typeof value === 'string') {
    // Reemplazar ',' y "'" por '.' para que parseFloat pueda entender el número correctamente
    const numericString = value.replace(/[,']/g, '.');
    // Convertir la cadena a número
    return parseFloat(numericString);
  }
  
  // Si el valor ya es numérico, retornarlo tal cual
  if (typeof value === 'number') {
    return value;
  }
  
  // Si el valor no es ni string ni número, retornar NaN
  return NaN;
}
