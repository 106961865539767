import {
  FireIcon
} from '@heroicons/react/20/solid'
import { useFetchExercisesQuery } from '../../store';
import { useEffect, useState } from 'react';
import { useCoach } from '../../useCoach';


function SearchExercise({onAddExercise}){
  const [term, setTerm] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const { coach } = useCoach();
  const { data, error, isLoading} = useFetchExercisesQuery(coach.id);
  
  let renderedExercises;
  if (isLoading){
    renderedExercises = <div> Loading</div>
  } else if (error){
    renderedExercises = <div> Errors</div>
  } else {
    renderedExercises = <ExerciseCards exercises={searchResult} onClick={onAddExercise} />;
  }
  
  useEffect(() => {
    if (!isLoading && !error) {
      setSearchResult(data);
    }
  }, [data, isLoading, error]);


  const handleChange = (e) =>{
    const value = e.target.value;
    setTerm(value);
    // Filtrar los resultados basados en el valor de term
    const filteredResults = data.filter(exercise => 
      exercise.name.toLowerCase().includes(value.toLowerCase())
    );

    setSearchResult(filteredResults);
  };

  return (
    <>
      <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
        <dl className="flex flex-wrap">
          <div className='mt-6 px-6 w-full '>
            <label htmlFor="exercise" className="block text-sm font-medium leading-6 text-gray-900">
              Search exercise
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <FireIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                type="exercise"
                name="exercise"
                id="exercise"
                value={term}
                onChange={handleChange}
                className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Bench Press"
              />
            </div>
          </div>
          <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6 mb-6">
            {renderedExercises}
          </div>
        </dl>
        
      </div>
    </>
  );
}

function ExerciseCards ({exercises=[], onClick}){
  const renderedExercises = exercises.map((exercise) => {
    const handleAdd = (exerciseId) =>{
      onClick(exerciseId);
    };
    return (
      <li key={exercise.id} className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6">
        <div className="flex min-w-0 gap-x-4">
          <div className="min-w-0 flex-auto">
            <p className="text-sm font-semibold leading-6 text-gray-900">
                {exercise.name}
            </p>
            <p className="mt-1 flex text-xs leading-5 text-gray-500 relative truncate hover:underline">
                {exercise.name}
            </p>
          </div>
        </div>
        <div className="flex flex-none items-center gap-x-4">
          <button
            onClick= {() => {
              handleAdd(exercise.id)
            }}
            className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
          >
            Add to training<span className="sr-only">, {exercise.name}</span>
          </button>
          
        </div>
      </li>
    )
  });
  return (
    <>
      <ul
        className="divide-y w-full divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl"
      >
         {renderedExercises}
      </ul>
    </>
  );
}

export default SearchExercise;