import React from "react";
import './main.css';
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { App } from "./app";
import { Provider } from 'react-redux';
import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate";
//import { Analytics } from "@vercel/analytics/react";
import { store } from './store';
import { CoachProvider } from "./CoachProvider";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <Auth0ProviderWithNavigate>
      <Provider store={store}>
        <CoachProvider>
          <App />
        </CoachProvider>
        {/* <Analytics /> */}
      </Provider>
    </Auth0ProviderWithNavigate>
  </BrowserRouter>
);


