
import { useFetchRecordByAthleteIdQuery } from "../../store";
import StandardCompoundLinearChart from "../charts/StandardCompoundLinearChart";
import TableCard from "../tables/TableCard";
import moment from "moment/moment";

export default function PRChart({athlete}) {
  const config = [
    {
      key: 'Date',
      label: 'Date',
      render: (pr) => moment(pr.created_at).format('DD/MM/YYYY'),
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Bench press',
      label: 'Bench Press',
      render: (pr) => pr.bench_press+' kg',
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Squat',
      label: 'Squat',
      render: (pr) => pr.squat+' kg',
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Deadlift',
      label: 'Deadlift',
      render: (pr) => pr.deadlift+' kg',
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Military Press',
      label: 'Military Press',
      render: (pr) => pr.military_press+' kg',
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Dips',
      label: 'Dips',
      render: (pr) => pr.dip+' kg',
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Pull Ups',
      label: 'Pull Ups',
      render: (pr) => pr.pull_up+' kg',
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
  ];
  const keyFn = (record) => {
    return record.id;
  };

  const { data, error, isLoading} = useFetchRecordByAthleteIdQuery(athlete.id); 
  let table;
  if (isLoading){
    table = <div> Loading</div>
  } else if (error){
    table = <div> Errors</div>
  } else {
    let chartData = data.slice().reverse();
    table = <StandardCompoundLinearChart data={chartData} config={config} keyFn={keyFn} chartTitle="Personal Records Variation" />;
  }
  return (
    <div>
        {table}
    </div>
  );
}