import SideBar from "../../components/navigation/SideBar";
import TableCard from "../../components/tables/TableCard";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useCreateExerciseMutation } from "../../store";
import StandardForm from "../../components/forms/StandardForm";
import { useCoach } from "../../useCoach";

function CreateExerciseForm(){
  const { coach } = useCoach();
  const navigate = useNavigate();
  const [createExercise, results] = useCreateExerciseMutation();
  const meta = {
      object: "Exercise",
      action: "Create new Exercise",
      description: "Create a new exercise record.",
    };
  
  const config = [
    {
      id:'name',
      label: 'Exercise name',
      type: 'shortText',
      placeholder: 'Bench Press',
      size: 2
    },
    {
      id:'video_tutorial',
      label: 'Link to video',
      type: 'shortText',
      placeholder: 'Video tutorial link',
      size: 2
    },
    {
      id:'description',
      label: 'Exercise Description',
      type: 'text',
      placeholder: 'Write how to conduct the exercise.',
      size: 'full'
    },
  ];
  

  const keyFn = (exercise) => {
    return exercise.id;
  };
  const handleCreate = (e)=> {
    e.preventDefault();
    var submitData = config.reduce((o, input) => (
      { ...o, 
        [input.id]: e.target[input.id].value, 
        coach: coach.id
      }), {});
    createExercise(submitData);
    navigate("/exercises");
  }

  return (
    <>
    <div>
      <SideBar/>
      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">
          <StandardForm meta={meta} config={config} colsForm={1} onSubmit={handleCreate}/>
        </div>
      </main>
    </div>
    </>
  );
}

export default CreateExerciseForm;