import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const authHeaders = { 
  apikey: process.env.REACT_APP_SUPABASE_APIKEY,
  authorization: process.env.REACT_APP_SUPABASE_AUTH_TOKEN,
};
const plicometryApi = createApi({
  reducerPath: 'plicometrie',
  tagTypes:['Plicometry'],
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://kzsfryzkbjkimvzprysp.supabase.co/rest/v1/'
  }),
  endpoints(builder){
    return {
      fetchPlicometries: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'Plicometry'}];
        },
        query: () => {
          return {
            url:'/plicometry',
            params:{
              // userId: user.id
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      createPlicometry: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'Plicometry'}];
        },
        query: (data) => {
          return {
            url:'/plicometry',
            body:data,
            method: 'POST',
            headers: {
              ...authHeaders,
              "Content-Type": 'application/json'
            },
          }
        }
      }),
      updatePlicometry: builder.mutation({
        invalidatesTags:(result, error)=>{
          return [{type:'Plicometry'}];
        },
        query: ({id:plicometryId,submitData}) => {
          return {
            url:'/plicometry',
            params:{
              id:"eq."+plicometryId
            },
            body:submitData,
            method: 'PATCH',
            headers: {
              ...authHeaders,
              "Content-Type": 'application/json'
            },
          }
        }
      }),
      fetchPlicometry: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'Plicometry'}];
        },
        query: (plicometryId) => {
          return {
            url:'/plicometry',
            params:{
              id:"eq."+plicometryId
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
      fetchPlicometryByAtheleteId: builder.query({
        providesTags:(result, error, )=>{
          return [{type:'Plicometry'}];
        },
        query: (athleteId) => {
          return {
            url:'/plicometry',
            params:{
              athlete:"eq."+athleteId
            },
            headers: authHeaders,
            method: 'GET',
          }
        }
      }),
    }
  }
});

export const { useFetchPlicometriesQuery, useCreatePlicometryMutation, useUpdatePlicometryMutation, useFetchPlicometryQuery, useFetchPlicometryByAtheleteIdQuery  } = plicometryApi;
export { plicometryApi };